import React, { useEffect, useState, useMemo } from 'react';
import { useLocation, useParams } from 'react-router-dom';
import './style-signup.css';
import { Datepicker } from "@mobiscroll/react";
import "@mobiscroll/react/dist/css/mobiscroll.min.css";
import { IValidateProps } from '@mobiscroll/react/dist/src/core/util/datetime';
import { CustomerRequest, Department, OrderedQuestion, PagingList, Question, Service, ServiceInfo, Shop, Config } from './types/types';
import { Preview } from './QuestionForm';
import { calculateOpeningHours } from './utils/openingHours';
import { BusyTime, OpenHours, TenantHome } from './types/types';
import { DateTime, Duration } from 'luxon';
import { calculateBounds } from './utils/getOpenAndClose';
import ErrorWrapper from './ErrorWrapper';
import PhoneInput from 'react-phone-number-input';
import 'react-phone-number-input/style.css';
import { isValidPhoneNumber } from 'react-phone-number-input';

type Tagged<A, T> = A & { __tag?: T };
type E164Number = Tagged<string, "E164Number">;

export function ScheduleForm(): JSX.Element {
    const location = useLocation();
    const searchParams = new URLSearchParams(location.search)
    const shopIdFilter = searchParams.get("shop");
    const appointmentFilter = searchParams.get("virtual");


    const [tenant, setTenant] = useState<TenantHome>();
    const [environment, setEnvironment] = useState<string>();

    const [shops, setShops] = useState<Shop[]>();
    const [selectedShop, setSelectedShop] = useState<Shop>();
    const [departments, setDepartments] = useState<Department[]>();
    const [selectedService, setSelectedService] = useState<ServiceInfo>();
    const [thinking, setThinking] = useState<boolean>(false);
    const [minDate, setMinDate] = useState<Date>()
    const [maxDate, setMaxDate] = useState<string>()
    const [minTime, setMinTime] = useState<string>();
    const [maxTime, setMaxTime] = useState<string>();
    const [marketing, setMarketing] = useState<boolean>(true);
    const [errorShow, setErrorShow] = useState<boolean>(false);
    const [name, setName] = useState<string>('');
    const [phone, setPhone] = useState<E164Number | undefined>('');
    const [email, setEmail] = useState<string>('');
    const [address, setAddress] = useState<string>('');
    const [zip, setZipCode] = useState<string>('');
    const [notes, setNotes] = useState<string>('');
    const [date, setDate] = useState<string>();
    const [time, setTime] = useState<Date>();
    const [loading, setLoading] = useState<boolean>(true);
    const [error, setError] = useState<boolean>(false);
    const [errorDetails, setDetailsError] = useState<any>({});
    const [validationErrors, setValidationErrors] = useState<string[]>([]);
    const [validTimes, setValidTimes] = useState<IValidateProps[]>([]);
    const { subdomain } = useParams();
    const [stepMinutes, setStepMinutes] = useState<number>();
    const [showThankYou, setShowThankYou] = useState<boolean>(false);
    const [responseText, setResponseText] = useState("");
    const [disableScrolling, setDisableScrolling] = useState<boolean>(false);
    const [isBooking, setIsBooking] = useState(false);
    const [configuration, setConfiguration] = useState<Config>();
    const [genericQuestions, setGenericQuestions] = useState<OrderedQuestion[]>([]);
    const [shopQuestions, setShopQuestions] = useState<OrderedQuestion[]>([]);
    const [departmentQuestions, setDepartmentQuestions] = useState<OrderedQuestion[]>([]);
    const [serviceQuestions, setServiceQuestions] = useState<OrderedQuestion[]>([]);
    const [questionResults, setQuestionResults] = useState<{ q: Question, s: number, a: string }[]>([]);
    const emailPattern = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;

    function createBooking() {
        const validationErrors = validate();
        if (validationErrors.length === 0) {
            setIsBooking(true);
            createCustomerAndAppointment().then((ok) => {
                if (ok) {
                    setShowThankYou(true);
                    setDisableScrolling(true);
                }
                setIsBooking(false);
            });
        } else {
            //validation error response            
            setValidationErrors(validationErrors);
            setDisableScrolling(true);
            console.log(validationErrors);
        }
    }

    function validate(): string[] {
        var validationErrors: string[] = [];
        if (!selectedService) {
            validationErrors.push("Select a service");
        }
        if (!time || time.valueOf() === minDate?.valueOf()) {
            validationErrors.push("Select a date and time");
        }
        if (!name) {
            validationErrors.push("Enter your name");
        }
        if (!email) {
            validationErrors.push("Enter your email");
        }
        if (email && !emailPattern.test(email)) {
            validationErrors.push("Enter a valid  email");
        }
        if (phone && !isValidPhoneNumber(phone as string)) {
            validationErrors.push("Enter a valid phone number");
        }
        if (configuration && configuration.mandatoryFields) {
            for (const field of configuration.mandatoryFields) {
                switch (field) {
                    case 'Address': if (!address) validationErrors.push("Enter your Address"); break;
                    case 'Phone': if (!phone) validationErrors.push("Enter your phone number"); else if (!isValidPhoneNumber(phone)) validationErrors.push("Enter a valid phone number"); break;
                    case 'Zip': if (!zip) validationErrors.push("Enter your zip code"); break;
                    case 'Notes': if (!notes) validationErrors.push("Tell us more about your request in notes"); break;
                }
            }
        }

        genericQuestions.filter(q => q.mandatory && !questionResults.some(qr => qr.a && qr.q.id === q.id)).forEach(q => validationErrors.push(`Tell us ${q.question}`));
        shopQuestions.filter(q => q.mandatory && !questionResults.some(qr => qr.a && qr.q.id === q.id)).forEach(q => validationErrors.push(`Tell us ${q.question}`));
        departmentQuestions.filter(q => q.mandatory && !questionResults.some(qr => qr.a && qr.q.id === q.id)).forEach(q => validationErrors.push(`Tell us ${q.question}`));
        serviceQuestions.filter(q => q.mandatory && !questionResults.some(qr => qr.a && qr.q.id === q.id)).forEach(q => validationErrors.push(`Tell us ${q.question}`));

        return validationErrors;
    }

    function closeThankyou() {
        setShowThankYou(false);
        setDisableScrolling(false);
        setName('');
        setEmail('');
        setAddress('');
        setPhone('');
        setNotes('');
        setZipCode('');
        setQuestionResults([])
        setSelectedService(undefined);
    }

    function closeValid() {
        setValidationErrors([]);
        setDisableScrolling(false);
    }

    function closeError() {
        setResponseText("");
        setDisableScrolling(false);
    }

    useEffect(() => {
        if (selectedShop) {
            fetch(`/api/config/${subdomain}/shop/${selectedShop.id}`)
                .then(response => {
                    if (response.ok) {
                        response.json()
                            .then((config: Config) => {
                                setConfiguration(config)
                            }).catch(e => {
                                setDetailsError(e)
                                setError(true)
                            });
                    } else {
                        setDetailsError({ message: "Error getting shop configuration please try again later." })
                        setError(true);
                    }
                }).catch(error => {
                    setDetailsError(error)
                    setError(true)
                });
        }
        fetch(`/api/questions/${subdomain}/generic/0`).then(r => r.json()).then((q: OrderedQuestion[]) => setGenericQuestions(q));
    }, [subdomain, selectedShop]);

    useEffect(() => {
        const minDate = new Date();
        if (configuration && configuration.bufferMinutes) {
            minDate.setMinutes(minDate.getMinutes() + configuration.bufferMinutes);
        }
        setMinDate(minDate);
        const maxDate = new Date(minDate);
        maxDate.setMonth(maxDate.getMonth() + 3);
        setMaxDate(maxDate.toISOString());
        fetch("/api/env").then(response => response.json().then(env => setEnvironment(env.environment)))
    }, [configuration])


    useEffect(() => {
        //get Tenant By SubDomain from Api....
        const fetchOptions = {
            headers: {
                'Content-Type': 'application/json',
                'Access-Control-Allow-Origin': '*'
            }
        }
        if (environment) {
            const apiUrl = environment === "local" || environment === "development" ?
                "https://dev-api.parla.tech/api/v1" :
                environment === "test" ?
                    "https://test-api.parla.tech/api/v1" :
                    "https://api.parla.tech/api/v1";

            fetch(`${apiUrl}/tenants/home?s=${subdomain}`, fetchOptions)
                .then(response => {
                    if (response.ok) {
                        response.json()
                            .then(tenantHome => {
                                setTenant(tenantHome);
                                setLoading(false);
                            });
                    } else {
                        setLoading(false);
                    }
                }).catch(error => {
                    setDetailsError(error); setLoading(false); setError(true)
                });
            fetch(`/api/shops/${subdomain}`)
                .then(r => r.json())
                .then((result: PagingList<Shop>) => {
                    var shops = result.items.filter(s => s.departments.length);
                    if (shopIdFilter) {
                        shops = shops.filter(s => s.id.toString() === shopIdFilter);
                    }
                    console.log("shops", shops);
                    setShops(shops);
                    if (shops.length >= 1) {
                        setSelectedShop(shops[0])
                    }
                });
        }
    }, [subdomain, environment, shopIdFilter]);

    useEffect(() => {
        if (selectedShop) {
            fetch(`/api/shop/${subdomain}/${selectedShop.id}`).then(r => r.json()).then((shop: Shop) => {
                setDepartments(shop.departments.filter(d => d.staffCount > 0))
            });
            setValidTimes([]);
            setSelectedService(undefined);
            fetch(`/api/questions/${subdomain}/shop/${selectedShop.id}`).then(r => r.json()).then((q: OrderedQuestion[]) => setShopQuestions(q));
        }
    }, [subdomain, selectedShop])

    useEffect(() => {
        if (selectedService) {
            setThinking(true);
            const minutes = getDuration(selectedService);
            fetch(`/api/config/${subdomain}/service/${selectedService.serviceId}`)
                .then(response => {
                    if (response.ok) {
                        response.json()
                            .then((config: Config) => {
                                if (config.slotSize)
                                    setStepMinutes(config.slotSize);
                                else {
                                    setStepMinutes(minutes);
                                }
                            }).catch(e => {
                                setDetailsError(e);
                                setError(true)
                            });
                    } else {
                        setDetailsError({ message: "Error getting service configuration please try again later." });
                        setError(true);
                    }
                }).catch(error => {
                    setDetailsError(error);
                    setError(true)
                });
            fetch(`/api/department/${subdomain}/${selectedService.departmentId}/hours`).then(r => r.json()).then((hours: OpenHours) => {
                if (date && subdomain && selectedService) {
                    const start = new Date(date);
                    start.setUTCHours(0, 0, 0, 0);
                    const end = new Date(start);
                    end.setUTCHours(23, 59, 59, 999);
                    fetch(`/api/department/${subdomain}/${selectedService.departmentId}/busy?start=${start.toISOString()}&end=${end.toISOString()}`).then(r => r.json()).then((busy: BusyTime[]) => {
                        if (hours && busy && minutes) {
                            const openingHours = calculateOpeningHours(hours, minutes, []);
                            const bounds = calculateBounds(openingHours, date, configuration);
                            setMinDate(DateTime.fromISO(bounds.minDate).toJSDate());
                            setMinTime(bounds.minTime);
                            setMaxTime(bounds.maxTime);
                            const openingHoursWithStaff = calculateOpeningHours(hours, minutes, busy);
                            setValidTimes(openingHoursWithStaff);
                            setThinking(false);
                        }
                    });
                } else {
                    setThinking(false);
                }
            });
            fetch(`/api/questions/${subdomain}/department/${selectedService.departmentId}`).then(r => r.json()).then((q: OrderedQuestion[]) => setDepartmentQuestions(q));
            fetch(`/api/questions/${subdomain}/service/${selectedService.serviceId}`).then(r => r.json()).then((q: OrderedQuestion[]) => setServiceQuestions(q));
        }
    }, [subdomain, selectedService, stepMinutes, date, configuration])

    function updateTime(time: Date) {
        if (time)
            setDate(DateTime.fromJSDate(time).toISODate()!);
        setTime(time);
    }

    async function createCustomerAndAppointment() {
        if (!time || !subdomain || !selectedService)
            return;
        const start = new Date(time);
        start.setUTCHours(0, 0, 0, 0);
        const end = new Date(start);
        end.setUTCHours(23, 59, 59, 999);
        const busyRequest = await fetch(`/api/department/${subdomain}/${selectedService.departmentId}/busy?start=${start.toISOString()}&end=${end.toISOString()}`);
        const busy: BusyTime[] = await busyRequest.json();
        const duration = getDuration(selectedService);
        if (duration) {
            const jsTime = DateTime.fromJSDate(time);
            const busyTime = busy.filter(bt => {
                console.log("COMPARING", bt, time, jsTime, duration);
                return DateTime.fromISO(bt.start) < jsTime.plus(Duration.fromObject({ minute: duration })) && DateTime.fromISO(bt.end) > jsTime
            })
            console.log("Busy", busy, busyTime);
            if (busyTime.length) {
                setResponseText("This time is no longer available");
                setDisableScrolling(true);
                setTime(new Date(time));
                return;
            }
        }
        const request: CustomerRequest = {
            subdomain: subdomain,
            name: name,
            phone: phone || null,
            email: email || null,
            marketing: marketing,
            address: address,
            zip: zip,
            notes: [notes, ...questionResults.sort((q1, q2) => q1.s - q2.s).map(q => `${q.q.question}: ${q.a}`)],
            service: selectedService,
            locale: navigator.language,
            timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone,
            appointmentTime: time.toISOString()
        }

        const options = {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
            },
            body: JSON.stringify(request)
        }
        console.log("Request Options", options);
        const response = await fetch(`/api/customer`, options);
        const content = await response.text();
        if (!response.ok) {
            setResponseText(content);
            setDisableScrolling(true);
        }
        return response.ok;
    }

    function selectShop(shop: Shop) {
        console.log(shop);
        setSelectedShop(shop);
    }

    function formatDuration(timespan: string): string {
        const parts = timespan.split(':');
        const hours = parseInt(parts[0]);
        const minutes = parseInt(parts[1]);
        const seconds = parseInt(parts[2]);

        let result = "";
        function addTimeChunk(value: number, label: string) {
            if (value === 1) {
                return `${value}\u00A0${label}`
            } else if (value > 1) {
                return `${value}\u00A0${label}s`
            }
        }

        if (hours > 0)
            result += addTimeChunk(hours, "hour");
        if (minutes > 0) {
            if (result)
                result += " ";
            result += addTimeChunk(minutes, "minute");
        }
        if (seconds > 0) {
            if (result)
                result += " ";
            result += addTimeChunk(seconds, "second");
        }

        return result;
    }

    function checkService(s: Service, d: Department, e: React.KeyboardEvent<HTMLDivElement>) {
        if (e.key === 'Enter' || e.key === ' ') {
            e.preventDefault();
            setSelectedService({ departmentId: d.id, serviceId: s.appointmentTemplate.id, duration: s.appointmentTemplate.duration })
        }
    }

    function getDuration(selectedService: ServiceInfo) {
        const duration = selectedService?.duration;

        if (duration) {
            const parts = duration.split(':');
            let minutes = parseInt(parts[1]);
            const seconds = parseInt(parts[2]);
            if (seconds > 0) {
                minutes += seconds / 60;
            }
            const hours = parseInt(parts[0]);
            minutes += hours * 60;
            return minutes;
        }
        return undefined;
    }

    function isMandatory(fieldName: string): boolean {
        return configuration?.mandatoryFields?.some(f => f === fieldName) || false;
    }

    const questionForm = useMemo(() => {
        function updateQuestionResults(question: Question, sequence: number, answer: string) {
            setQuestionResults(qs => {
                const QandA = qs.find(q => q.q.id === question.id);
                if (QandA) {
                    QandA.a = answer;
                    return qs;
                } else {
                    return [...qs, { q: question, s: sequence, a: answer }]
                }
            });
        }

        return <Preview questions={[...genericQuestions, ...shopQuestions, ...departmentQuestions, ...serviceQuestions]} onQuestionAnswered={updateQuestionResults} />
    }, [genericQuestions, shopQuestions, departmentQuestions, serviceQuestions]);

    if (loading) {
        return <div>Loading</div>
    } else if (!environment) {
        return <div>No Environment</div>
    } else if (error) {
        return <ErrorWrapper hasError error={errorDetails} />
    }

    function applyFilter(services: Service[]): Service[] {
        return services.filter(s => s.appointmentTemplate.virtual === (appointmentFilter === "true"));
    }

    if (tenant) {
        return (
            <div className="scheduler">
                <link rel="stylesheet" type="text/css" href={`/api/css/${subdomain}.css`} />
                {responseText ?
                    <div className={`modal-wrap`} aria-modal={true}>
                        <div className='modal'>
                            <i className="fas fa-exclamation-triangle"></i>
                            <div className='thankyou-modal-title'>An Error occurred</div>
                            <div className='accordion'>
                                <p className='accordion-header' onClick={() => setErrorShow((prev) => !prev)}>
                                    <span>Detailed error message</span>
                                    {errorShow ? <i className="fas fa-chevron-up"></i> : <i className="fas fa-chevron-down"></i>}
                                </p>
                                {errorShow && <div className='accordion-body'>{responseText}</div>}
                            </div>
                            <button
                                tabIndex={100}
                                className='thankyou-modal-btn'
                                onClick={closeError}
                                autoFocus
                                ref={inputElement => {
                                    if (inputElement) {
                                        inputElement.focus();
                                    }
                                }}>Close
                            </button>
                        </div>
                    </div>
                    : null}
                {showThankYou ?
                    <div className={`modal-wrap`} aria-modal={true}>
                        <div className='modal'>
                            <div className='thankyou-modal-title'>Thank you for booking with us!</div>
                            <i className="fas fa-check-circle"></i>
                            <div className='thankyou-modal-text'> You will get a confirmation email very soon.</div>
                            <button
                                tabIndex={100}
                                className='thankyou-modal-btn'
                                onClick={closeThankyou}
                                autoFocus
                                ref={inputElement => {
                                    if (inputElement) {
                                        inputElement.focus();
                                    }
                                }}>Close
                            </button>
                        </div>
                    </div>
                    : null}
                {validationErrors.length ?
                    <div className={`modal-wrap`} aria-modal={true}>
                        <div className='modal'>
                            <div className='validation-modal-title'> Please insert the fields listed below to proceed with the booking:</div>
                            {validationErrors.map(v => <div key={v} className='validation-error'>{v}</div>)}
                            <button
                                tabIndex={100}
                                className='thankyou-modal-btn'
                                onClick={closeValid}
                                autoFocus
                                ref={inputElement => {
                                    if (inputElement) {
                                        inputElement.focus();
                                    }
                                }}>Close
                            </button>
                        </div>
                    </div>
                    : null}
                {!shopIdFilter ?
                    <header>
                        <div className='location-title'>Select Your Location Below to Get Started:</div>
                        <div className='location-options'>
                            {shops ?
                                shops.map(s => <label key={`shop${s.id}`} className={s.id === selectedShop?.id ? "active" : undefined}><i className="fas fa-map-marker-alt"></i><input type="radio" name="shop" onChange={() => { selectShop(s) }} />{s.address.area}, {s.address.region}</label>)
                                : null}
                        </div>
                    </header> : null}
                <main className={disableScrolling ? "noscroll" : undefined}>
                    <div className="form-border">
                        <div className="form-area">
                            <div className='shop-form-container'>
                                <div className='tenant-details'>
                                    <img src={tenant.small.content} alt={"Company Logo"} className="icon" />
                                    <div className='shop-name'><i className="fas fa-store"></i> {selectedShop?.name}</div>
                                </div>
                                <hr id='serviceSelect' />
                                <span className='signup-form-title'>Select Service</span>
                                <div className='shop-services'>
                                    {departments && departments.length && departments.reduce((p, v) => p + v.services.length, 0) ?
                                        departments.map((d, i) =>
                                            (appointmentFilter ? applyFilter(d.services) : d.services)
                                                .map((s, j) =>
                                                    <div tabIndex={i * 2 + j + 1} onKeyDown={(e) => checkService(s, d, e)} key={`service${s.appointmentTemplate.id}`} onClick={() => setSelectedService({ departmentId: d.id, serviceId: s.appointmentTemplate.id, duration: s.appointmentTemplate.duration })} className={selectedService?.departmentId === d.id && selectedService.serviceId === s.appointmentTemplate.id ? 'shop-services-item active' : 'shop-services-item'}>
                                                        <span>{d.name}</span>
                                                        <span><i className={(s.appointmentTemplate.virtual) ? 'fal fa-globe fa-fw' : 'fas fa-building fa-fw'}></i>{s.appointmentTemplate.name}</span>
                                                        <span><i className="fal fa-clock fa-fw"></i>{formatDuration(s.appointmentTemplate.duration)}</span>
                                                    </div>
                                                )
                                        ) :
                                        <span>No services available at your location</span>
                                    }
                                </div>
                                <hr />
                                <div className='calendar-scheduler mbsc-datepicker-tab-wrapper'>
                                    <span className='signup-form-title'>Select Date & Time</span>
                                    <Datepicker
                                        controls={['calendar', 'timegrid']}
                                        min={minDate}
                                        max={maxDate}
                                        minTime={minTime}
                                        maxTime={maxTime}
                                        stepMinute={stepMinutes}
                                        //labels={myLabels} 
                                        valid={validTimes}
                                        display="inline"
                                        theme='material'
                                        themeVariant='light'
                                        value={time}
                                        onChange={e => { console.log(e.value, minTime, maxTime); updateTime(e.value) }}
                                    />
                                    {selectedService ? null : <a className='time-blocked' href='#serviceSelect'>Select a Service</a>}
                                    <div className='think-parent'>{thinking ? <><div></div><div className='time-blocked'>Thinking...</div></> : null}</div>
                                </div>
                                <div className="signup-form">
                                    <hr />
                                    <span className='signup-form-title'>Add Your Details</span>
                                    <div className='appointment-form'>
                                        <div className='form-input-wrapper'>
                                            <span className="form-input">
                                                <input type="text" aria-label={name} aria-required="true" value={name} onChange={(e) => { setName(e.target.value) }} placeholder="Name" name="name" required />
                                            </span>
                                            <span className="form-input">
                                                <input type="email" aria-label={email} aria-required="true" value={email} onChange={(e) => { setEmail(e.target.value) }} placeholder="Email" name="email" required />
                                            </span>
                                            <span className="form-input">
                                                <input type="text" aria-label={address} aria-required="false" value={address} onChange={(e) => { setAddress(e.target.value) }} placeholder={isMandatory("Address") ? "Address" : "Address (optional)"} name="address" required={isMandatory("Address")} />
                                            </span>
                                            <span className="form-input">
                                                <input type="text" aria-label={zip} aria-required="false" value={zip} onChange={(e) => { setZipCode(e.target.value) }} placeholder={isMandatory("Zip") ? "Zip Code" : "Zip Code (optional)"} name="zip" required={isMandatory("Zip")} />
                                            </span>
                                            <span className="form-input">
                                                <PhoneInput
                                                    international={true}
                                                    withCountryCallingCode={true}
                                                    placeholder={isMandatory("Phone") ? "Phone Number" : "Phone Number (optional)"}
                                                    value={phone as any}
                                                    onChange={(value) => { setPhone(value) }}
                                                    type="phone" aria-label={"phone"} aria-required={isMandatory("Phone")}
                                                    name="phone" required={isMandatory("Phone")}
                                                />
                                            </span>
                                        </div>
                                        <div className='form-input-special'>
                                            <div className='form-input-special-title'>Please let us know if you have any special requests. Thank you.</div>
                                            <textarea value={notes} onChange={(e) => setNotes(e.target.value)} dir="ltr" aria-label="Please let us know if you have any special requests. Thank you." placeholder={isMandatory("Notes") ? "Notes" : "Notes (optional)"} rows={5} required={isMandatory("Notes")}></textarea>
                                        </div>
                                    </div>
                                    {questionForm}
                                    <div className="name-panel-input">
                                        <label>
                                            <input type="checkbox" checked={marketing} onChange={(e) => { setMarketing(e.target.checked) }} />
                                            <span className="label">We respect your privacy and will never rent or sell your information.  By providing your email address you are opting-in to receive emails and you may unsubscribe at any time. By entering your mobile number, you are opting-in to receive mobile alerts and offers. Standard text rates may apply.</span>
                                        </label>
                                    </div>
                                </div>
                            </div>
                            <button className="submit" onClick={createBooking} disabled={isBooking}>{isBooking ? 'Creating Appointment...' : 'Book Appointment'}</button>

                        </div>
                        <div className='footer'>
                            <div className='parla-powered'>
                                <span className='powered-by'>POWERED BY <a target="_blank" rel="noreferrer" href='https://www.parlaretail.com'><img src="/parla-logo-gradient.png" alt="Parla Logo" /></a></span><br />
                                © Parla Retail Ltd
                            </div>

                        </div>
                    </div>
                </main>
            </div>
        )
    } else {
        return <div>Not Found</div>
    }
}