export enum Permission {
    None = 0,
    ReadProperty = 1,
    ReadSingle = 2,
    List = 4,
    Update = 8,
    Create = 16,
    Delete = 32,
}

export type PermissionEntry = {
    target: string,
    accessLevel: Permission
}

export const permissions: PermissionEntry[] = [
    { target: "shop", accessLevel: Permission.List | Permission.ReadSingle | Permission.ReadProperty },
    { target: "address", accessLevel: Permission.ReadSingle },
    { target: "department", accessLevel: Permission.ReadSingle | Permission.List },
    { target: "staff", accessLevel: Permission.ReadProperty},
    { target: "openinghours", accessLevel: Permission.ReadProperty },
    { target: "openinghoursexception", accessLevel: Permission.List },
    { target: "appointmenttemplate", accessLevel: Permission.ReadSingle | Permission.List},
    { target: "customer", accessLevel: Permission.Create | Permission.List },
    { target: "journey", accessLevel: Permission.Create },
    { target: "videocall", accessLevel: Permission.Create },
    { target: "appointment", accessLevel: Permission.Create },
    { target: "integrationconfiguration", accessLevel: Permission.ReadSingle }
]

export function permissionToUrl(permissions: PermissionEntry[]): string {
    return permissions.map(p => `${p.target}.${p.accessLevel}`).join(",");
}
