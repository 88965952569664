import { Question } from './types/types';
import { LinkedQuestion } from './Config/AssignQuestions';

export function SelectableQuestion(props: { question: Question; chosenQuestions: LinkedQuestion[], onAdd: () => void; }): JSX.Element {

    const { question, chosenQuestions, onAdd } = props;
    const selected = chosenQuestions.some(cq => cq.id === question.id);

    return <li onClick={() => onAdd()} className='question-item'>
        <button>
            {question.question}
            {selected ? <i className='fas fa-check' /> : <i className='fas fa-plus faded' />}
        </button>
    </li>;
}
