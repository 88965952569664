import { MbscRecurrenceRule } from "@mobiscroll/react";
import { DateType, IValidateProps } from "@mobiscroll/react/dist/src/core/util/datetime";
import { DateTime, Duration, Zone } from "luxon";
import { BusyTime, OpenHours, OpeningHours, OpeningHoursException, ExceptionStatus } from "../types/types";
import { getDayOfTheWeek, moveDayOfTheWeek } from ".";


export function pushHours(day: OpeningHours, push: (start: DateTime, end: DateTime, dow: number) => void, duration: number): void {
    const shopStart = DateTime.fromISO(day.start, { zone: day.timeZone });
    const shopEnd = DateTime.fromISO(day.end, { zone: day.timeZone });
    const start = shopStart
    const end = shopEnd.minus(Duration.fromObject({ minutes: duration }))
    const weekday = day.dayOfTheWeek
    push(start, end, weekday);
}

export function addDay(day: OpeningHours, hours: IValidateProps[], duration: number): void {
    pushHours(day, (s, e, w) => {
        hours.push({
            start: s,
            end: e,
            recurring: {
                repeat: 'weekly',
                weekDays: getDayOfTheWeek(w)
            }
        });
    }, duration)
}

export function addDays(i: number, openhours: OpeningHours[], hours: IValidateProps[], duration: number): void {
    if (openhours) {
        const day = openhours.find(d => d.dayOfTheWeek === i);
        const hour = hours.find(d => (typeof d.recurring !== "string") && d.recurring?.weekDays === getDayOfTheWeek(i));
        if (day && !hour) {
            if (day.closed) {
                hours.push({
                    recurring: {
                        repeat: 'weekly',
                        weekDays: getDayOfTheWeek(i)
                    }
                })
            } else {
                addDay(day, hours, duration);
            }
        }
    }
}

export function cleanClosedDays(hours: IValidateProps[]) {
    for (let i = hours.length - 1; i >= 0; i--) {
        if (!hours[i].start) {
            //closed days don't always have start times so don't format them
            console.log("REMOVING CLOSED DAY", hours[i]);
            hours.splice(i, 1);
        }
    }
}
export function format(h: IValidateProps) {
    //console formatting
    if ((h.start as any).isLuxonDateTime) {
        return { ...h, start: (h.start as any).toISO(), end: (h.end as any).toISO(), zone: (h.start as DateTime).zoneName }
    }
    return h;
}

export function formatHours(hours: IValidateProps[]) {
    const result: IValidateProps[] = [];
    for (const hour of hours) {
        result.push(format(hour));
    }
    return result;
}

export function split(time: DateType | undefined, destinationZone: Zone): { hour: number; minute: number; second: number, millisecond: number } {
    if ((time as any).isLuxonDateTime) {
        const dt = time as DateTime;
        dt.setZone(destinationZone)
        return { hour: dt.hour, minute: dt.minute, second: 0, millisecond: 0 };
    }
    var parts = (time as string).split(':');
    return { hour: parseInt(parts[0]), minute: parseInt(parts[1]), second: 0, millisecond: 0 };
}

export function exceptionOnExistingSlot(hours: IValidateProps[], hour: IValidateProps, exceptionStart: DateTime, exceptionEnd: DateTime, duration: number) {
    //This is the case where a second exception intercepts an existing exception, such as busy times
    //The previous exception will have left an "open" area, which this exception will split
    //So create a new open area from the end of this exception, until the end of the existing open area
    //then update the existing area end to the start of this exception

    //Should never happen, this means there's no intersection, but that can only happen if getImpactedHours is giving out crap data
    if ((hour.end as DateTime) < exceptionStart || (hour.start as DateTime) > exceptionEnd)
        return;

    console.log("Existing slot interrupted", format(hour), exceptionStart.toISO(), exceptionEnd.toISO())
    const before = { start: hour.start, end: exceptionStart };
    const index = hours.indexOf(hour);
    console.log("Existing hours", index);
    console.log("Removing Existing hours", index, format(hour));
    hours.splice(index, 1);
    if (before.start && before.start < before.end) {
        before.end = exceptionStart.minus({ minutes: duration })
        console.log("Before", format(before));
        hours.push(before)
    }
    const after = { start: exceptionEnd, end: hour.end };
    //hour.end = exceptionStart.minus({ minutes: duration });
    if (after.end && after.end > after.start) {
        console.log("New slot", format(after))
        hours.push(after);
    } else {
        console.log("After new slot is invalid");
    }
}

export function addRecurrenceBreak(hour: IValidateProps, exceptionDate: string): null | (() => void) {
    //Defer until after we've checked that this entry is valid
    var createRecurringException = () => { };
    //The timezone can shift this to the previous day so that needs to be taken into account
    if (hour.recurringException && hour.recurringException instanceof Array) {
        //There's already an exception on this date - we don't need to an another exception - 
        //if we're in the newly opened hours then expect another entry in impacted hours

        console.log("Adding Recurrence Break:", exceptionDate, hour.recurringException)
        if (hour.recurringException.includes(exceptionDate)) {
            //We've already excepted this day, so we can ignore the whole day and 
            //look for changes in other non-recurring impacted hours
            console.log("Ignoring exception on excepted day")
            return null;
        } else {
            createRecurringException = () => {
                if (hour.recurringException && hour.recurringException instanceof Array) {
                    hour.recurringException.push(exceptionDate);
                }
            }
        }
    } else {
        createRecurringException = () => {
            hour.recurringException = [exceptionDate]
        }
    }
    return createRecurringException
}

export function createExceptionBlocks(hours: IValidateProps[], hour: IValidateProps, day: DateTime, exceptionStart: DateTime, exceptionEnd: DateTime, duration: number): boolean {
    //As the exception can span multiple days, set the date of the exception to match the current impacted day

    //Settng the date like this ends up on the wrong day when timezones roll the days over on either side    
    const start = exceptionStart.set({ year: day.year, month: day.month, day: day.day }).setZone((hour.start as DateTime).zone);
    const end = exceptionEnd.set({ year: day.year, month: day.month, day: day.day }).setZone((hour.start as DateTime).zone);
    console.log("Exception date is ", start.toISO(), end.toISO());
    const beforeEnd = start.minus({ minutes: duration });
    const beforeStart = start.set(split(hour.start, start.zone));
    console.log("Before is ", beforeStart.toISO(), beforeEnd.toISO());
    const afterEnd = end.set(split(hour.end, end.zone));
    console.log("After is ", end.toISO(), afterEnd.toISO());

    const before = { start: beforeStart, end: beforeEnd };
    const after = { start: end, end: afterEnd };
    //It's possible this exception already exists - in the case of an opening hours extension, the regular hours overlap will cause an exception 
    //to be created here and so will the opening hours exception.

    if (hours.some(h => before.end.equals(h.end as DateTime) && before.start.equals(h.start as DateTime))) {
        console.log("before exception already exists");
    } else if (before.start > before.end) {
        console.log("before start is after before end")
        if (after.start < before.start) {
            //A busy time or exception is outside the opening hours and will peel the opening hours back to the end of the appointment
            return false;
        }
    } else {
        hours.push(before);
    }

    if (hours.some(h => after.end.equals(h.end as DateTime) && after.start.equals(h.start as DateTime))) {
        console.log("after exception already exists");
    } else if (after.start > after.end)
        console.log("after start is after after end")
    else
        hours.push(after);

    return true
}

export function overlaps(h: IValidateProps, exceptionDate: DateTime, exceptionStart: DateTime, exceptionEnd: DateTime, dayOfTheWeek: number, duration: number) {
    const date = { year: exceptionDate.year, month: exceptionDate.month, day: exceptionDate.day };
    console.log("OVERLAPS ", format(h), exceptionDate.toISO(), exceptionStart.toISO(), exceptionEnd.toISO(), dayOfTheWeek)
    if (h.recurring) {
        if (h.start && h.end) {
            const hStart = (h.start as DateTime).set(date).setZone(exceptionDate.zone);
            //The existing hours in the array have the final "slot" removed , to see if this exception overlaps we need to add the slot back before comparing
            const hEnd = (h.end as DateTime).set(date).plus({ minutes: duration }).setZone(exceptionDate.zone);
            const start = exceptionDate.set(split(exceptionStart, exceptionDate.zone));
            const end = exceptionDate.set(split(exceptionEnd, exceptionDate.zone));
            const weekDay = getDayOfTheWeek(dayOfTheWeek);
            const recurrance = (h.recurring as MbscRecurrenceRule);
            if (recurrance.from && recurrance.until) {
                if ((DateTime.fromISO(recurrance.from as string) > exceptionDate) || (DateTime.fromISO(recurrance.until as string) < exceptionDate)) {
                    console.log("Outside recurrance range")
                    return false;
                }
            }
            if (recurrance.weekDays !== weekDay) {
                console.log("Wrong day of the week", recurrance.weekDays, weekDay)
                return false;
            }
            const result = hStart < end && hEnd > start;
            if (!result)
                console.log("Outside hours", hStart.toISO(), end.toISO(), hEnd.toISO(), start.toISO())
            return result;
        } else {
            console.log("Missing start and/or end")
            return false;
        }
    } else if (h.start && typeof (h.start) !== "string" && h.end && typeof (h.end) !== "string") {
        const start = exceptionStart.set(date);
        const end = exceptionEnd.set(date);
        const result = h.start < end && h.end > start;
        if (!result)
            console.log("Missed existing exception", start.toISO(), end.toISO(), h)
        return result;
    } else {
        const hStart = exceptionDate.set(split(h.start, exceptionDate.zone));
        const hEnd = exceptionDate.set(split(h.end, exceptionDate.zone));
        const start = exceptionStart.set(date);
        const end = exceptionEnd.set(date);
        const result = hStart < end && hEnd > start && !h.recurring
        if (!result)
            console.log("Missed existing exception && !recurring")
        return result;
    }
}

export function getExceptionDates(start: DateTime, end: DateTime, timezone: string, dayOfTheWeek: number): string[] {
    var current = start;
    const dates: string[] = [];
    if (dayOfTheWeek === 0)
        dayOfTheWeek = 7;
    console.log("GetExceptionDates", "Checking Dates", current.toISO(), end.toISO(), dayOfTheWeek)
    while (current <= end) {
        if (current.weekday === dayOfTheWeek) {
            if (current >= DateTime.now().setZone(timezone).startOf('day')) {
                var stringDate = current.toISODate();
                if (stringDate) {
                    dates.push(stringDate);
                }
            } else {
                console.log("Current day is in the past", current.toISO(), DateTime.now().setZone(timezone).startOf('day').toISO())
            }
        }
        current = current.plus(Duration.fromObject({ days: 1 }));
    }
    return dates;
}

export function except(hours: IValidateProps[], exception: OpeningHoursException, duration: number) {
    const exceptionEndDate = DateTime.fromISO(exception.endDate).setZone(exception.timeZone, { keepLocalTime: true });
    if (exceptionEndDate.toLocal() < DateTime.now()) {
        console.log("Ignoring Past exception");
        return
    }
    const exceptionBegin = DateTime.fromISO(exception.startDate).setZone(exception.timeZone, { keepLocalTime: true });
    //The exception day of the week is a little... odd 
    //Most of this method is working in LOCAL time, but most of the exceptions are working in SHOP time, that means that any timezone shift
    //between SHOP and LOCAL results in the day of the week overlapping the days either side.
    const exceptionDates = getExceptionDates(exceptionBegin, exceptionEndDate, exception.timeZone, exception.dayOfTheWeek);
    console.log("Applying exception to dates", exceptionDates, exception.timeZone);
    for (const exceptionDate of exceptionDates) {
        const day = DateTime.fromISO(exceptionDate).setZone(exception.timeZone, { keepLocalTime: true });
        /// If the exception start is a fully formated the following line shunts it by the timezone offset.
        //if it's partially formatted it correctly adjusts the timezone.
        //Specifying the "zone" should only apply to partially formatted time strings
        const exceptionStart = DateTime.fromISO(exception.start, { zone: exception.timeZone }).setZone(exception.timeZone, { keepLocalTime: true });
        const exceptionEnd = DateTime.fromISO(exception.end, { zone: exception.timeZone }).setZone(exception.timeZone, { keepLocalTime: true });
        console.log("Localising exception to shop time", exception, exceptionStart.toISO(), exceptionEnd.toISO());
        //Need to check the non-recurring dates first.
        var impactedHours: IValidateProps[] = hours.filter(h => !h.recurring && overlaps(h, day, exceptionStart, exceptionEnd, exception.dayOfTheWeek, duration));
        if (!impactedHours.length) {
            impactedHours = hours.filter(h => h.recurring && overlaps(h, day, exceptionStart, exceptionEnd, exception.dayOfTheWeek, duration));
        }


        ///If ANY recurring hours get impacted, ALL recurring hours in the date need cancelling and replacing with an exceptions
        //Find any non-impacted hours with a matching date

        //Mark them with a recurring exception
        //Add new matching hours with the same hours for this date.

        console.log("Adding exception", exception, formatHours(hours), formatHours(impactedHours));
        for (const hour of impactedHours) {
            if (hour.recurring) {
                const createRecurringException = addRecurrenceBreak(hour, exceptionDate)
                if (!createRecurringException) {
                    continue
                }
                console.log("Day is ", day.toISO());
                if (createExceptionBlocks(hours, hour, day, exceptionStart, exceptionEnd, duration)) {
                    createRecurringException()
                }
            } else {
                exceptionOnExistingSlot(hours, hour, exceptionStart, exceptionEnd, duration);
            }
        }
    }
}

export function addExceptions(hours: IValidateProps[], exceptions: OpeningHoursException[], duration: number) {
    if (exceptions) {
        for (const exception of exceptions) {
            console.log(`Adding ${exception.status === ExceptionStatus.open ? 'open' : 'closed'} exception`, exception);
            if (exception.status === ExceptionStatus.open) {
                //When creating an open exception we should create a recurring exception for the matching daterange in the base hours
                var impactedDates = getExceptionDates(DateTime.fromISO(exception.startDate).setZone(exception.timeZone, { keepLocalTime: true }), DateTime.fromISO(exception.endDate).setZone(exception.timeZone, { keepLocalTime: true }), exception.timeZone, exception.dayOfTheWeek);
                console.log("Open Exception Impacted Dates", impactedDates);
                for (const date of impactedDates) {
                    const day = DateTime.fromISO(date).setZone(exception.timeZone, { keepLocalTime: true });
                    const exceptionStart = DateTime.fromISO(exception.start, { zone: exception.timeZone }).setZone(exception.timeZone, { keepLocalTime: true });
                    const exceptionEnd = DateTime.fromISO(exception.end, { zone: exception.timeZone }).setZone(exception.timeZone, { keepLocalTime: true });
                    const impactedHours = hours.filter(h => h.recurring && overlaps(h, day, exceptionStart, exceptionEnd, exception.dayOfTheWeek, duration));
                    for (const hour of impactedHours) {
                        if (hour.recurring) {
                            const createRecurringException = addRecurrenceBreak(hour, date);
                            if (createRecurringException)
                                createRecurringException();
                        }
                    }
                }
                pushHours({ ...exception, closed: false }, (s, e, w) => {
                    hours.push({
                        start: s,
                        end: e,
                        recurring: {
                            repeat: 'weekly',
                            from: DateTime.fromISO(exception.startDate).toUTC().setZone(exception.timeZone, { keepLocalTime: true }).toISO()!,
                            until: DateTime.fromISO(exception.endDate).toUTC().setZone(exception.timeZone, { keepLocalTime: true }).toISO()!,
                            weekDays: getDayOfTheWeek(w)
                        }
                    });
                }, duration)
            } else {
                except(hours, exception, duration);
            }
        }
    }
}

export function convertHoursToLocal(shopTime: string, hours: IValidateProps[]): IValidateProps[] {
    const result = [];
    for (const hour of hours) {
        if (hour.recurring) {
            const shopStart = DateTime.fromISO(hour.start?.toString()!).setZone(shopTime);
            var localStart = shopStart.toLocal();
            const shopEnd = DateTime.fromISO(hour.end?.toString()!).setZone(shopTime);
            var localEnd = shopEnd.toLocal();
            if (localStart.day !== shopStart.day || localEnd.day !== shopEnd.day) {
                //midnight rollover
                if (localStart.day !== shopStart.day) {
                    console.warn("Day Start Rollover");
                    const previousDayRollover = { ...hour };
                    const recurrance = { ...(previousDayRollover.recurring as MbscRecurrenceRule) };
                    recurrance.weekDays = moveDayOfTheWeek(recurrance.weekDays!, -1)
                    previousDayRollover.recurring = recurrance;
                    previousDayRollover.start = localStart.toFormat("HH:mm");
                    previousDayRollover.end = "24:00";
                    result.push(previousDayRollover);
                    localStart = localStart.set({ day: shopStart.day, hour: 0, minute: 0 });
                }
                if (localEnd.day !== shopEnd.day) {
                    console.warn("Day End Rollover");
                    const nextDayRollover = { ...hour };
                    const recurrance = { ...(nextDayRollover.recurring as MbscRecurrenceRule) };
                    recurrance.weekDays = moveDayOfTheWeek(recurrance.weekDays!, 1)
                    nextDayRollover.recurring = recurrance;
                    nextDayRollover.start = "00:00";
                    nextDayRollover.end = localEnd.toFormat("HH:mm");
                    result.push(nextDayRollover);
                    localEnd = localEnd.set({ day: shopEnd.day, hour: 24, minute: 0 });
                }
            }
            const localHour = {
                ...hour,
                start: localStart.toFormat("HH:mm"),
                end: localEnd.toFormat("HH:mm"),
            }
            result.push(localHour);

        } else {
            const localHour = {
                ...hour,
                start: DateTime.fromISO(hour.start?.toString()!).setZone(shopTime).toLocal(),
                end: DateTime.fromISO(hour.end?.toString()!).setZone(shopTime).toLocal(),
            }
            result.push(localHour);
        }
    }
    return result;
}

export function findTimeZone(hours: OpenHours): string {
    for (const shopHours of hours.shop.openingHours) {
        if (shopHours.timeZone)
            return shopHours.timeZone;
    }
    for (const departmentHours of hours.department.openingHours) {
        if (departmentHours.timeZone)
            return departmentHours.timeZone;
    }
    return "utc";
}

export function calculateOpeningHours(openingHours: OpenHours, duration: number, busyTimes: BusyTime[]) {

    console.log(JSON.stringify({ openingHours, duration, busyTimes }))

    const hours: IValidateProps[] = [];

    console.log("Opening hours", openingHours);

    const shopTime = findTimeZone(openingHours)

    for (var i = 0; i < 7; i++) {
        addDays(i, openingHours.department.openingHours, hours, duration);
        addDays(i, openingHours.shop.openingHours, hours, duration);
    }
    cleanClosedDays(hours);

    console.log("Adding shop exceptions", openingHours.shop.exceptions);
    addExceptions(hours, openingHours.shop.exceptions, duration);
    console.log("Adding department exceptions", openingHours.department.exceptions);
    addExceptions(hours, openingHours.department.exceptions, duration);

    for (const busy of busyTimes) {
        console.log("Adding Busy time", busy)
        const start = DateTime.fromISO(busy.start);
        const end = DateTime.fromISO(busy.end);
        if (start.isValid && end.isValid) {
            except(hours, { startDate: start.toISODate()!, endDate: end.plus({ days: 1 }).toISODate()!, start: busy.start, end: busy.end, timeZone: 'utc', dayOfTheWeek: start.toUTC().weekday, status: 1 }, duration);
        }
    }

    console.log("Shop Final Hours", formatHours(hours));
    const customerLocalHours = convertHoursToLocal(shopTime, hours);
    console.log("Customer Final Hours", formatHours(customerLocalHours));
    return customerLocalHours;
}

