import { useEffect, useState } from "react";
import { Config, Service, PagingList, ConfigRequest, AppointmentTemplate } from '../types/types';
import { nameof } from "../utils";
import { ConfigPageProps } from ".";
import { useNavigate } from "react-router-dom";
import ReactPaginate from "react-paginate";

export function ServiceConfigPage(props: ConfigPageProps) {
    const [configuration, setConfiguration] = useState<Config>({});
    const [saving, setSaving] = useState<boolean>();
    const [services, setServices] = useState<AppointmentTemplate[]>([]);
    const [pageCount, setPageCount] = useState<number>(0);
    const [page, setPage] = useState<number>(1);
    const [_default, setDefault] = useState<boolean>();
    const [serviceId, setServiceId] = useState<number | undefined>();
    const [serviceName, setServiceName] = useState<string>("Default");
    const { subdomain, id } = props;
    const navigate = useNavigate();

    useEffect(() => {
        const url = serviceId ? `/api/config?id=${id}&service=${serviceId}` : `/api/config?id=${id}`;
        fetch(url)
            .then(response => {
                if (response.ok) {
                    try {
                        response.json()
                            .then((config: Config) => {
                                console.log("LOADING CONFIG", config)
                                setDefault(config.isDefault);
                                setConfiguration(config);
                            });
                    } catch (e) {
                        console.log(e);
                        setConfiguration({});
                    }
                }
            })
    }, [id, serviceId]);

    useEffect(() => {
        if (subdomain) {
            fetch(`/api/services/${subdomain}?page=${page}`)
                .then(r => {
                    if (r.ok) {
                        return r.json()
                    }
                })
                .then((result: PagingList<AppointmentTemplate>) => {
                    if (result) {
                        const pageCount = Math.ceil(result.count / 10.0);
                        console.log("PAGE COUNT", result, pageCount)
                        setPageCount(pageCount);
                        var services = result.items;
                        console.log("services", services);
                        setServices(services);
                    }
                });
        }
    }, [subdomain, page])

    function updateConfiguration<T>(propertyName: keyof (Config), value: T) {
        setConfiguration((config: Config) => {
            return { ...config, [propertyName]: value };
        })
    }

    async function saveConfiguration() {
        setSaving(true);
        if (id) {
            const config: ConfigRequest = {
                tenant_id: id,
                service_id: serviceId,
                config: {
                    ...configuration,
                    slotSize: configuration.slotSize,
                }
            }
            console.log("SAVING CONFIGURATION", config);
            const options = {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                },
                body: JSON.stringify(config)
            }
            await fetch(`/api/config`, options).then(() => {
                setDefault(false);
                setTimeout(() => { setSaving(false) }, 2000)
            })
        }
    }

    return <div className='configuration'>
        <div className='service-config'>
            <div className='select-service'>
                <button className='service-option' onClick={() => { setServiceId(undefined); setServiceName("Default") }}>Default Settings</button>
                {services.map(s => <button className='service-option' key={s.id} onClick={() => { setDefault(false); setServiceId(s.id); setServiceName(s.name) }}>{s.name}</button>)}
                <ReactPaginate
                    pageRangeDisplayed={3}
                    className="paginate"
                    nextLabel='>'
                    previousLabel='<'
                    pageCount={pageCount}
                    onPageChange={(e) => { console.log("SET PAGE", e.selected + 1); setPage(e.selected + 1) }}
                    forcePage={page - 1} />
            </div>
            <div className='config-settings'>
                <div className='selected-preview-panel-title'>
                    <h3>Configure {serviceName} {_default && serviceId && `(using default settings)`}</h3>
                    <div onClick={() => navigate("config")}><i className="fas fa-chevron-left"></i> Back</div>
                </div>
                <div className='buffer-setup'>
                    <h4>Slot Size</h4>
                    <span className="buffer-alt-txt">Minutes between slots while booking</span>
                    <input type={'number'} placeholder="Minutes before booking" value={configuration.slotSize || 0} onChange={(e) => updateConfiguration(nameof<Config>("slotSize"), parseInt(e.target.value))} />
                </div>
                <button className='save-btn btn ready' onClick={() => { saveConfiguration() }}>
                    {saving ? <span className='fade-out-text'>Configuration Saved!</span> : <span className='fade-in-text'>Save Configuration</span>}
                </button>
            </div>
        </div>
    </div>
}