import { OrderedQuestion, Question } from "./types/types";
import { QuestionElement } from "./QuestionElement";

export function Preview(props: { questions: OrderedQuestion[], onQuestionAnswered?: (q: Question, sequence: number, value: string) => void }): JSX.Element {

    const questions = props.questions.sort((q1, q2) => q1.sequence - q2.sequence);

    console.log("Loading Preview", props);

    return <div>
        {questions && questions.length ?
            <div>
                <div>
                    <span className="signup-form-title">Please answer the questions below</span>
                </div>
                {questions.map(q => <QuestionElement key={q.linkId} sequence={q.sequence} mandatory={q.mandatory} question={({ ...q, choices: JSON.parse(q.choices) })} onQuestionAnswered={props.onQuestionAnswered} />)}
            </div>
            : null}
    </div>
}