import { Question } from "./types/types";
import { CheckboxQuestion, RadioQuestion, SelectQuestion, TextQuestion } from "./Questions";

export function QuestionElement(props: { question: Question; sequence: number, mandatory: boolean; onQuestionAnswered?: (q: Question, sequence: number, value: string) => void; }): JSX.Element {
    const q = props.question;

    console.log(q);

    function updateParentValue(s: string) {
        console.log(s);
        if (props.onQuestionAnswered)
            props.onQuestionAnswered(props.question, props.sequence,  s);
    }

    if (q.questionType === 'text')
        return <TextQuestion question={q} mandatory={props.mandatory} valueChanged={updateParentValue} />;
    else if (q.questionType === 'single') {
        if (q.choices.length > 4)
            return <SelectQuestion question={q} mandatory={props.mandatory} valueChanged={updateParentValue} />;

        else
            return <RadioQuestion question={q} mandatory={props.mandatory} valueChanged={updateParentValue} />;
    } else if (q.questionType === 'multi') {
        return <CheckboxQuestion question={q} mandatory={props.mandatory} valueChanged={updateParentValue} />;
    } else {
        return <div>unknown question type {q.questionType}</div>;
    }
}
