import { useCallback, useEffect, useMemo, useState } from "react";
import { ConfigPageProps } from ".";
import {
  Department,
  LinkKind,
  OrderedQuestion,
  PagingList,
  Question,
  Shop,
} from "../types/types";
import { Thing } from "../Thing";
import { SelectableQuestion } from "../SelectableQuestion";
import { ShopTree } from "../ShopTree";
import { useNavigate } from "react-router-dom";
import { EditableQuestionForm } from "./EditableQuestionForm";
import { Preview } from "../QuestionForm";

export interface LinkedQuestion extends OrderedQuestion {
  added: false;
  type: LinkKind;
  linkedId?: number;
}

interface Question4 {
  added: true;
  selected: Thing;
  mandatory: boolean;
  question: Question;
}

interface QuestionConfirmation {
  usage: JSX.Element;
  target: Question4 | LinkedQuestion;
  action?: "add" | "remove";
}

export function AssignQuestions(props: ConfigPageProps) {
  const [questions, setQuestions] = useState<Question[]>([]);
  const [shops, setShops] = useState<Shop[]>([]);
  const [genericQuestions, setGenericQuestions] = useState<LinkedQuestion[]>(
    []
  );
  const [shopQuestions, setShopQuestions] = useState<LinkedQuestion[]>([]);
  const [departmentQuestions, setDepartmentQuestions] = useState<
    LinkedQuestion[]
  >([]);
  const [serviceQuestions, setServiceQuestions] = useState<LinkedQuestion[]>(
    []
  );
  const [chosenQuestions, setChosenQuestions] = useState<LinkedQuestion[]>([]);
  const [selected, setSelected] = useState<Thing>({ company: "All Shops" });
  const [showRealPreview, setShowRealPreview] = useState<boolean>();
  const [warnings, setWarnings] = useState<string[]>([]);
  const [activeWarning, setActiveWarning] = useState<string>();
  const [showConfirmAddQuestion, setShowConfirmAddQuestion] =
    useState<QuestionConfirmation>();
  const navigate = useNavigate();
  const { subdomain, tenantName, token } = props;
  const currentSelection =
    selected?.service ??
    selected?.department ??
    selected?.shop ??
    selected.company;

  const loadQuestions = useCallback(() => {
    //Load generic questions
    fetch(`/api/questions/${subdomain}/generic/0?t=${token}`)
      .then((r) => r.json())
      .then((q: OrderedQuestion[]) => {
        console.log("Generic", q);
        setGenericQuestions(
          q.map((q) => ({ ...q, type: "generic", linkedId: 0, added: false }))
        );
      });
    fetch(`/api/questions?t=${token}`)
      .then((r) => r.json())
      .then((questions) => {
        const qs: Question[] = [];
        for (const q of questions) {
          qs.push({ ...q, choices: JSON.parse(q.choices) });
        }
        setQuestions(qs);
      });
  }, [subdomain, token]);

  useEffect(() => {
    if (subdomain) {
      loadQuestions();
      fetch(`/api/shops/${subdomain}`)
        .then((r) => {
          if (r.ok) {
            return r.json();
          }
        })
        .then((result: PagingList<Shop>) => {
          if (result) {
            var shops = result.items.filter((s) => s.departments.length);
            console.log("shops", shops);
            setShops(shops);
          }
        });
    }
  }, [subdomain, loadQuestions]);

  useEffect(() => {
    if (selected) {
      fetch(`/api/questions/${subdomain}/generic/0`)
        .then((r) => r.json())
        .then((qs: OrderedQuestion[]) => {
          const companyQuestions: LinkedQuestion[] = qs.map((q) => ({
            ...q,
            type: "generic",
            linkedId: 0,
            sequence: q.sequence < 1 ? 1 : q.sequence,
            added: false,
          }));
          setGenericQuestions(companyQuestions);
          if (!selected.shopId) {
            setChosenQuestions(companyQuestions);
          }
        });

      if (selected.shopId) {
        fetch(`/api/questions/${subdomain}/shop/${selected.shopId}`)
          .then((r) => r.json())
          .then((qs: OrderedQuestion[]) => {
            const shopQuestions: LinkedQuestion[] = qs.map((q) => ({
              ...q,
              type: "shop",
              linkedId: selected.shopId,
              sequence: q.sequence < 1 ? 1 : q.sequence,
              added: false,
            }));
            setShopQuestions(shopQuestions);
            if (!selected.departmentId) {
              setChosenQuestions(shopQuestions);
            }
          });
      } else {
        setShopQuestions([]);
      }

      if (selected.departmentId) {
        fetch(`/api/questions/${subdomain}/department/${selected.departmentId}`)
          .then((r) => r.json())
          .then((qs: OrderedQuestion[]) => {
            const departmentQuestions: LinkedQuestion[] = qs.map((q) => ({
              ...q,
              type: "department",
              linkedId: selected.departmentId,
              sequence: q.sequence < 1 ? 1 : q.sequence,
              added: false,
            }));
            setDepartmentQuestions(departmentQuestions);
            if (!selected.serviceId) {
              setChosenQuestions(departmentQuestions);
            }
          });
      } else {
        setDepartmentQuestions([]);
      }

      if (selected.serviceId) {
        fetch(`/api/questions/${subdomain}/service/${selected.serviceId}`)
          .then((r) => r.json())
          .then((qs: OrderedQuestion[]) => {
            const serviceQuestions: LinkedQuestion[] = qs.map((q) => ({
              ...q,
              type: "service",
              linkedId: selected.serviceId,
              sequence: q.sequence < 1 ? 1 : q.sequence,
              added: false,
            }));
            setServiceQuestions(serviceQuestions);
          });
      } else {
        setServiceQuestions([]);
      }
    }
  }, [subdomain, selected]);

  useEffect(() => {
    if (!activeWarning && warnings.length) {
      const updatedWarning = [...warnings];
      setActiveWarning(updatedWarning.shift());
      console.log("WARNING UPDATED", activeWarning, warnings, updatedWarning);
      setWarnings(updatedWarning);
      setTimeout(() => {
        setActiveWarning(undefined);
      }, 3000);
    }
  }, [warnings, activeWarning]);

  function questionToLinkedQuestion(
    question: Question,
    qs: LinkedQuestion[],
    mandatory: boolean,
    kind: LinkKind,
    linkedId: number,
    linkId: number
  ): LinkedQuestion {
    return {
      ...question,
      sequence: qs.reduce((p, q) => (p > q.sequence ? p : q.sequence + 1), 1),
      choices: JSON.stringify(question.choices),
      mandatory: mandatory,
      type: kind,
      linkId: linkId,
      linkedId: linkedId,
      added: false,
    };
  }

  interface IdResponse {
    id: number;
  }

  const addQuestion = useCallback(
    (q: Question4) => {
      function addQuestionToLinkedQuestions(
        qs: LinkedQuestion[],
        question: Question,
        mandatory: boolean,
        kind: LinkKind,
        linkedId: number,
        linkId: number
      ): LinkedQuestion[] {
        console.log("addQuestionToLinkedQuestions", question);
        var existing = qs.filter(
          (q) =>
            q.id === question.id && q.linkedId === linkedId && q.type === kind
        );
        if (existing.length === 0) {
          return [
            ...qs,
            questionToLinkedQuestion(
              question,
              qs,
              mandatory,
              kind,
              linkedId,
              linkId
            ),
          ];
        } else {
          setWarnings((w) => [
            ...w,
            `Question "${question.question}" already added to ${currentSelection}`,
          ]);
          return qs;
        }
      }

      async function addQuestion(q: Question4) {
        const { question, mandatory, selected } = q;
        if (selected) {
          const kind: LinkKind = selected.serviceId
            ? "service"
            : selected.departmentId
            ? "department"
            : selected.shopId
            ? "shop"
            : "generic";
          const id =
            selected.serviceId ?? selected.departmentId ?? selected.shopId ?? 0;
          console.log("Adding Question", question, "to", selected, mandatory);
          const updateResponse = await fetch(
            `/api/questions/link?q=${question.id}&k=${kind}&id=${id}&mandatory=${mandatory}&order=1`,
            { method: "POST" }
          );
          const idHolder: IdResponse = await updateResponse.json();
          switch (kind) {
            case "department":
              setDepartmentQuestions((qs) =>
                addQuestionToLinkedQuestions(
                  qs,
                  question,
                  mandatory,
                  kind,
                  id,
                  idHolder.id
                )
              );
              break;
            case "shop":
              setShopQuestions((qs) =>
                addQuestionToLinkedQuestions(
                  qs,
                  question,
                  mandatory,
                  kind,
                  id,
                  idHolder.id
                )
              );
              break;
            case "generic":
              setGenericQuestions((qs) =>
                addQuestionToLinkedQuestions(
                  qs,
                  question,
                  mandatory,
                  kind,
                  id,
                  idHolder.id
                )
              );
              break;
            case "service":
              setServiceQuestions((qs) =>
                addQuestionToLinkedQuestions(
                  qs,
                  question,
                  mandatory,
                  kind,
                  id,
                  idHolder.id
                )
              );
              break;
          }
        } else {
          console.log("Nothing selected");
        }
      }
      addQuestion(q);
    },
    [currentSelection]
  );

  function removeQuestion(question: LinkedQuestion) {
    console.log("Remove", question);
    const kind = question.type;
    const id = question.linkedId;
    fetch(`/api/questions/unlink?q=${question.id}&k=${kind}&id=${id}`, {
      method: "POST",
    });
    switch (kind) {
      case "department":
        setDepartmentQuestions((qs) =>
          qs.filter(
            (q) =>
              !(q.id === question.id && q.linkedId === id && q.type === kind)
          )
        );
        break;
      case "shop":
        setShopQuestions((qs) =>
          qs.filter(
            (q) =>
              !(q.id === question.id && q.linkedId === id && q.type === kind)
          )
        );
        break;
      case "generic":
        setGenericQuestions((qs) =>
          qs.filter(
            (q) =>
              !(q.id === question.id && q.linkedId === id && q.type === kind)
          )
        );
        break;
      case "service":
        setServiceQuestions((qs) =>
          qs.filter(
            (q) =>
              !(q.id === question.id && q.linkedId === id && q.type === kind)
          )
        );
        break;
    }
  }

  const checkQuestionEffects = useCallback(
    (question: Question4 | LinkedQuestion, remove?: boolean) => {
      function kindAndId(question: Question4 | LinkedQuestion) {
        console.log("Kind", question);
        if (question.added) {
          const id =
            question.selected.serviceId ??
            question.selected.departmentId ??
            question.selected.shopId ??
            0;
          const kind: LinkKind = question.selected.serviceId
            ? "service"
            : question.selected.departmentId
            ? "department"
            : question.selected.shopId
            ? "shop"
            : "generic";
          return { id, kind };
        } else {
          return { id: question.linkedId, kind: question.type };
        }
      }
      /* function body */
      async function checkQuestionEffects(
        question: Question4 | LinkedQuestion
      ) {
        const { kind, id } = kindAndId(question);
        console.log("checkQuestionEffects", question, kind, id);
        if (kind === "generic") {
          //Show message to confirm this will affect ALL options
          const usage = (
            <h3>All shops, departments and services will be affected</h3>
          );
          setShowConfirmAddQuestion({
            target: question,
            usage: usage,
            action: remove ? "remove" : "add",
          });
        } else if (kind === "service") {
          console.log("Hello");
          //Find where this service is used, if it's more than one department then show a message displaying every where it's used.
          const findUsageResponse = await fetch(
            `/api/usage/${subdomain}/service/${id}`
          );
          const usage = await findUsageResponse.json();
          let message = (
            <div>
              <h3>The following departments will be affected:</h3>
              <ul>
                {" "}
                {usage.items.map((d: Department) => (
                  <li>
                    {d.shopName}: {d.name}
                  </li>
                ))}
                {usage.count > 10 ? (
                  <li>And {usage.count - 10} others</li>
                ) : null}
              </ul>
            </div>
          );
          console.log(usage);
          if (usage) {
            setShowConfirmAddQuestion({ target: question, usage: message });
          }
        } else {
          if (question.added) addQuestion(question);
          else removeQuestion(question);
        }
      }
      checkQuestionEffects(question);
    },
    [subdomain, addQuestion]
  );

  const previewform = useMemo(() => {
    async function updateQuestion(
      question: LinkedQuestion,
      mandatory: boolean,
      sequence: number
    ) {
      function updateQuestionInLinkedQuestions(
        qs: LinkedQuestion[],
        question: LinkedQuestion
      ): LinkedQuestion[] {
        return [
          ...qs.filter(
            (q) =>
              !(
                q.id === question.id &&
                q.linkedId === question.linkedId &&
                q.type === question.type
              )
          ),
          question,
        ];
      }

      const kind = question.type;
      const id = question.linkedId;
      question.mandatory = mandatory;
      question.sequence = isFinite(sequence) ? sequence : 1;
      console.log("Updating Question", question, "to", selected, mandatory);
      await fetch(
        `/api/questions/link?q=${question.id}&k=${kind}&id=${id}&mandatory=${mandatory}&order=${question.sequence}`,
        { method: "POST" }
      );
      switch (kind) {
        case "department":
          setDepartmentQuestions((qs) =>
            updateQuestionInLinkedQuestions(qs, question)
          );
          break;
        case "shop":
          setShopQuestions((qs) =>
            updateQuestionInLinkedQuestions(qs, question)
          );
          break;
        case "generic":
          setGenericQuestions((qs) =>
            updateQuestionInLinkedQuestions(qs, question)
          );
          break;
        case "service":
          setServiceQuestions((qs) =>
            updateQuestionInLinkedQuestions(qs, question)
          );
          break;
      }
    }

    console.log("Generic", genericQuestions);
    console.log("Shop", shopQuestions);
    console.log("department", departmentQuestions);
    console.log("service", serviceQuestions);
    return showRealPreview ? (
      <Preview
        questions={[
          ...genericQuestions,
          ...shopQuestions,
          ...departmentQuestions,
          ...serviceQuestions,
        ]}
      />
    ) : (
      <EditableQuestionForm
        selected={selected}
        companyQuestions={genericQuestions}
        shopQuestions={shopQuestions}
        departmentQuestions={departmentQuestions}
        serviceQuestions={serviceQuestions}
        onChange={updateQuestion}
        onDelete={(q) => checkQuestionEffects(q, true)}
      />
    );
  }, [
    genericQuestions,
    shopQuestions,
    departmentQuestions,
    serviceQuestions,
    showRealPreview,
    selected,
    checkQuestionEffects,
  ]);

  return (
    <div className="selected-preview-panel">
      {showConfirmAddQuestion ? (
        <div
          onClick={() => setShowConfirmAddQuestion(undefined)}
          className="modal-wrap"
        >
          <div onClick={(e) => e.stopPropagation()} className="modal">
            <div className="modal-content">{showConfirmAddQuestion.usage}</div>
            <button
              className="btn save-btn"
              onClick={async () => {
                if (showConfirmAddQuestion.action === "remove") {
                  removeQuestion(
                    showConfirmAddQuestion.target as LinkedQuestion
                  );
                } else {
                  addQuestion(showConfirmAddQuestion.target as Question4);
                }
                setShowConfirmAddQuestion(undefined);
              }}
            >
              {" "}
              Confirm
            </button>
            <button
              className="btn danger-btn"
              onClick={() => setShowConfirmAddQuestion(undefined)}
            >
              Cancel
            </button>
          </div>
        </div>
      ) : null}
      <div className="selected-preview-panel-title">
        <h3>Assign Questions</h3>
        <div onClick={() => navigate("config")}>
          <i className="fas fa-chevron-left"></i> Back
        </div>
      </div>
      <div className="preview">
        <ShopTree
          shops={shops}
          tenantName={tenantName}
          subdomain={subdomain}
          onChange={setSelected}
        />
        <div className="preview-list-view">
          <div className="preview-list-form-view">
            <div className="questions">
              <h4>Add Question to {currentSelection}</h4>
              <ol>
                {questions.map((q) => (
                  <SelectableQuestion
                    key={q.id}
                    chosenQuestions={chosenQuestions}
                    question={q}
                    onAdd={() => {
                      checkQuestionEffects({
                        question: q,
                        mandatory: false,
                        selected: selected,
                        added: true,
                      });
                    }}
                  />
                ))}
              </ol>
            </div>
            <div className="preview-form">
              <div>
                <div className="preview-header">
                  <h4>
                    {showRealPreview ? "Previewing" : "Editing"} Questions
                  </h4>
                  {!selected.serviceId ? (
                    <span className="warning">
                      May be incomplete until a service is selected
                    </span>
                  ) : null}
                </div>
                <div>
                  <button
                    className="preview-button"
                    onClick={() => {
                      setShowRealPreview(!showRealPreview);
                    }}
                  >
                    {showRealPreview ? "Edit Questions" : "Show Preview"}
                  </button>
                </div>
              </div>
              {selected?.service} {selected?.department} {selected?.shop}
              {previewform}
            </div>
          </div>
        </div>
        {
          <div className={activeWarning ? "popup popup-warning" : "popup"}>
            {activeWarning}
          </div>
        }
      </div>
    </div>
  );
}
