import React, { useEffect } from 'react';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import { ScheduleForm } from './ScheduleForm';
import Config from './Config';
import { RegisterPage } from './RegisterPage';
import './style.css';

function App() {
  useEffect(() => {
    fetch("/api/env").then(response => response.json().then(({ environment }) => {
      const parlaScript = document.getElementById('parla-script')
      if (environment === 'production' && !parlaScript) {
        const script = document.createElement('script');
        script.defer = true;
        script.setAttribute('id', "parla-script")
        script.setAttribute('data-domain', "customerscheduler")
        script.src = "https://plausible.apps.parla.tech/js/script.js";
        document.head.appendChild(script);
      }
    }))
  }, [])

  console.log("App Reloaded")

  return (
    <BrowserRouter>
      <Routes>
        <Route path="schedule/:subdomain" element={<ScheduleForm />} />
        <Route path="config/*" element={<Config />} />
        <Route path="register" element={<RegisterPage />} />
      </Routes>
    </BrowserRouter>
  );
}

export default App;