import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom"
import { PermissionEntry, permissionToUrl, permissions } from "../permissions";
import { ConfigPageProps } from ".";


export function PageSelection(props: ConfigPageProps) {
    const [permissionFault, setPermissionFault] = useState<boolean>();

    const { token, environment, subdomain } = props;
    const navigate = useNavigate();

    const adminPanel = environment === 'development' ? `https://${subdomain}.dev-admin.parla.tech` : environment === 'test' ? `https://${subdomain}.test-admin.parla.tech` : `https://${subdomain}.admin.parla.tech`;

    function getIntegrationId(): number {
        switch (environment) {
            case "development": return 7;
            case "production": return 9;
            case "test": return 9;
            default: return 0;
        }
    }

    useEffect(() => {
        fetch(`/api/permissions?t=${token}`).then(res => res.json()).then((result: PermissionEntry[]) => {
            const p2 = permissions.slice();
            const p1 = result.slice();
            while (p2.length > 0) {
                const permission = p2[0];
                if (!p1.some(p => p.target === permission.target && p.accessLevel === permission.accessLevel)) {
                    console.log("Permission check failed", permission, p1);
                    //incorrect permission
                    break;
                } else {
                    //correct, remove from both lists
                    p1.splice(p1.findIndex(p => p.target === permission.target), 1);
                    p2.splice(0, 1);
                }
            }
            if (p1.length > 0 || p2.length > 0)
                setPermissionFault(true);
        });
    }, [token])


    const hostUrl = environment === "development" ?
        "https://customer-scheduler.dev-apps.parla.tech" :
        environment === 'test' ?
            "https://customer-scheduler.test-apps.parla.tech" :
            "https://customer-scheduler.apps.parla.tech";

    const copyToClipboard = () => {
        navigator.clipboard.writeText(`<iframe src=${hostUrl + '/schedule/' + subdomain} width="100%" height="100%" scrolling="yes" style="border: 0; height: 2132px;">`)
    };

    return <div className="config">
        {permissionFault ? <div>The permissions for this integration have changed
            <a className='auth' href={`${adminPanel}/auth?integration=${getIntegrationId()}&permissions=${permissionToUrl(permissions)}`}>Authorise</a>
        </div> : null}
        <div className="area-routing">
            <button className='area-selector' onClick={() => navigate("/config/shops")}>Configure Shops</button>
            <button className='area-selector' onClick={() => navigate("/config/services")}>Configure Services</button>
            <button className='area-selector' onClick={() => navigate("/config/questions")}>Configure Questions</button>
            <button className='area-selector' onClick={() => navigate("/config/assign")}>Assign Questions</button>
        </div>
        <div className='iframe'>
            <h4>Embed</h4>
            <span className="buffer-alt-txt">Click to Copy</span>
            <div className='code'>
                <code onPointerDown={() => copyToClipboard()} onClick={() => copyToClipboard()}>
                    &lt;iframe src={hostUrl + '/schedule/' + subdomain} width="100%" height="100%" scrolling="yes" style="border: 0; height: 2132px;" /&gt;
                </code>
            </div>
        </div>
    </div>
}