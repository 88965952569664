import FlipMove from "react-flip-move";
import { Thing } from "../Thing";
import { LinkedQuestion } from "./AssignQuestions";
import { Component } from "react";

interface EditableQuestionFormProps {
    selected: Thing,
    companyQuestions: LinkedQuestion[],
    shopQuestions: LinkedQuestion[],
    departmentQuestions: LinkedQuestion[],
    serviceQuestions: LinkedQuestion[],
    onChange: (q: LinkedQuestion, mandatory: boolean, sequence: number) => void
    onDelete: (q: LinkedQuestion) => void
}

interface EditableQuestion extends LinkedQuestion {
    sourceType?: string
    source?: string
}
interface EditableQuestionItemProps {
    question: EditableQuestion;
    onChange: (q: LinkedQuestion, mandatory: boolean, sequence: number) => void
    onDelete: (q: LinkedQuestion) => void;
}

interface EditableQuestionItemState {
    sequence: number;
    timeout: NodeJS.Timeout | undefined;
}

class EditableQuestionItem extends Component<EditableQuestionItemProps, EditableQuestionItemState> {
    constructor(props: EditableQuestionItemProps) {
        super(props);
        this.state = { sequence: this.props.question.sequence, timeout: undefined };
    }
    onChange = (value: number) => {
        if (this.state.timeout) {
            clearTimeout(this.state.timeout);
        }
        const timeout = setTimeout(() => this.props.onChange(this.props.question, this.props.question.mandatory, value), 1500);
        this.setState({ sequence: value, timeout: timeout });
    }

    increasePriority() {
        this.onChange(this.state.sequence - 1)
    }

    decreasePriority() {
        this.onChange(this.state.sequence + 1)
    }

    render() {
        const { question, onDelete, onChange } = this.props;

        return (
            <li className='editable-question'>
                <div className="delete-question">
                    <button className='btn danger-btn' onClick={() => onDelete(question)}><i className='fas fa-trash' /></button>
                </div>
                <div className="question-preview">{question.question}</div>
                <div className="question-source"><label>Assigned from {question.sourceType}</label><span className="question-source-result">{question.source}</span></div>
                <div className="question-mandatory"><label>Questions is</label>
                    <label className="option-label">Mandatory<input className="mandatory-radio" type='radio' name={`${question.id}-${question.type}-${question.linkedId}`} checked={question.mandatory} onChange={(e) => { onChange(question, e.target.checked, question.sequence) }}></input></label>
                    <label className="option-label">Optional<input className="mandatory-radio" type='radio' name={`${question.id}-${question.type}-${question.linkedId}`} checked={!question.mandatory} onChange={(e) => { onChange(question, !e.target.checked, question.sequence) }}></input></label>
                </div>
                <div className="question-priority">
                    <label>Question Priority</label>
                    <input className='priority' value={this.state.sequence} onChange={(e) => { this.onChange(parseInt(e.target.value)) }}></input>
                    <button className='priority-button' onClick={() => this.increasePriority()}><i className="fas fa-long-arrow-alt-up"></i></button>
                    <button className='priority-button' onClick={() => this.decreasePriority()}><i className="fas fa-long-arrow-alt-down"></i></button>
                </div>
            </li>
        )
    }
}

export function EditableQuestionForm(props: EditableQuestionFormProps): JSX.Element {

    const questions: EditableQuestion[] = [...props.companyQuestions.map(q => ({ ...q, sourceType: 'company', source: props.selected.company })),
    ...props.shopQuestions.map(q => ({ ...q, sourceType: 'shop', source: props.selected.shop })),
    ...props.departmentQuestions.map(q => ({ ...q, sourceType: 'department', source: props.selected.department })),
    ...props.serviceQuestions.map(q => ({ ...q, sourceType: 'service', source: props.selected.service }))
    ]

    const sortedQuestions = questions.sort((q1, q2) => q1.sequence - q2.sequence);

    console.log("SORTED QUESTIONS", sortedQuestions)

    return <div>
        {sortedQuestions && sortedQuestions.length ?
            <div>
                <FlipMove
                    duration={500}
                    delay={0}
                    easing={"ease-in-out"}
                    typeName="ol">
                    {sortedQuestions.map(q => <EditableQuestionItem key={`${q.id}-${q.linkId}-${q.linkedId}-${q.type}`} question={q} onChange={props.onChange} onDelete={props.onDelete}></EditableQuestionItem>)}
                </FlipMove>
            </div>
            : null}
    </div>
}