import { useEffect, useState } from "react";
import { Config, ConfigRequest, Shop, PagingList } from '../types/types';
import { nameof } from "../utils";
import { ConfigPageProps } from ".";
import { useNavigate } from "react-router-dom";
import ReactPaginate from "react-paginate";

export function ShopConfigPage(props: ConfigPageProps) {
    const [configuration, setConfiguration] = useState<Config>({});
    const [saving, setSaving] = useState<boolean>();
    const [shops, setShops] = useState<Shop[]>([]);
    const [pageCount, setPageCount] = useState<number>(0);
    const [page, setPage] = useState<number>(1);
    const [_default, setDefault] = useState<boolean>();
    const [shopId, setShopId] = useState<number | undefined>();
    const [shopName, setShopName] = useState<string>("Default");
    const { subdomain, id } = props;
    const navigate = useNavigate();

    useEffect(() => {
        const url = shopId ? `/api/config?id=${id}&shop=${shopId}` : `/api/config?id=${id}`;
        fetch(url)
            .then(response => {
                if (response.ok) {
                    try {
                        response.json()
                            .then((config: Config) => {
                                console.log("LOADING CONFIG", config)
                                setDefault(config.isDefault);
                                setConfiguration(config);
                            });
                    } catch (e) {
                        console.log(e);
                        setConfiguration({});
                    }
                }
            })
    }, [id, shopId]);

    useEffect(() => {
        if (subdomain) {
            fetch(`/api/shops/${subdomain}?page=${page}`)
                .then(r => {
                    if (r.ok) {
                        return r.json()
                    }
                })
                .then((result: PagingList<Shop>) => {
                    if (result) {
                        const pageCount = Math.ceil(result.count / 10.0);
                        console.log("PAGE COUNT", result, pageCount)
                        setPageCount(pageCount);
                        var shops = result.items.filter(s => s.departments.length);
                        console.log("shops", shops);
                        setShops(shops);
                    }
                });
        }
    }, [subdomain, page])

    function toggleMandatoryField(fieldName: string) {
        const fields = configuration.mandatoryFields || [];
        const index = fields.indexOf(fieldName);
        if (index > -1) {
            fields.splice(index, 1);
        } else {
            fields.push(fieldName);
        }
        updateConfiguration("mandatoryFields", fields);
    }

    function updateConfiguration<T>(propertyName: keyof (Config), value: T) {
        setConfiguration((config: Config) => {
            return { ...config, [propertyName]: value };
        })
    }

    async function saveConfiguration() {
        setSaving(true);
        if (id) {
            const config: ConfigRequest = {
                tenant_id: id,
                shop_id: shopId,
                config: {
                    ...configuration,
                    bufferMinutes: configuration.bufferMinutes,
                    mandatoryFields: configuration.mandatoryFields || []
                }
            }
            console.log("SAVING CONFIGURATION", config);
            const options = {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                },
                body: JSON.stringify(config)
            }
            await fetch(`/api/config`, options).then(() => {
                setDefault(false);
                setTimeout(() => { setSaving(false) }, 2000)
            })
        }
    }

    return <div className='configuration'>
        <div className='shop-config'>
            <div className='select-shop'>
                <button className='shop-option' onClick={() => { setShopId(undefined); setShopName("Default") }}>Default Settings</button>
                {shops.map(s => <button className='shop-option' key={s.id} onClick={() => { setDefault(false); setShopId(s.id); setShopName(s.name) }}>{s.name}</button>)}
                <ReactPaginate
                    pageRangeDisplayed={3}
                    className="paginate"
                    nextLabel='>'
                    previousLabel='<'
                    pageCount={pageCount}
                    onPageChange={(e) => { setPage(e.selected) }}
                    forcePage={page - 1} />
            </div>
            <div className='config-settings'>
                <div className='selected-preview-panel-title'>
                    <h3>Configure {shopName} {_default && shopId && `(using default settings)`}</h3>
                    <div onClick={() => navigate("config")}><i className="fas fa-chevron-left"></i> Back</div>
                </div>
                <div className='buffer-setup'>
                    <h4>Buffer Time</h4>
                    <span className="buffer-alt-txt">Time required before next appointment.</span>
                    <input type={'number'} placeholder="Minutes before booking" value={configuration.bufferMinutes || 0} onChange={(e) => updateConfiguration(nameof<Config>("bufferMinutes"), parseInt(e.target.value))} />
                </div>
                <div className='mandatory-fields-setup'>
                    <h4>Mandatory Fields</h4>
                    <label><input type='checkbox' onChange={() => { toggleMandatoryField('Address') }} checked={configuration.mandatoryFields?.some(m => m === 'Address') || false} />Address</label>
                    <label><input type='checkbox' onChange={() => { toggleMandatoryField('Zip') }} checked={configuration.mandatoryFields?.some(m => m === 'Zip')|| false} />Zip Code</label>
                    <label><input type='checkbox' onChange={() => { toggleMandatoryField('Phone') }} checked={configuration.mandatoryFields?.some(m => m === 'Phone')|| false} />Phone Number</label>
                    <label><input type='checkbox' onChange={() => { toggleMandatoryField('Notes') }} checked={configuration.mandatoryFields?.some(m => m === 'Notes')|| false} />Notes</label>
                </div>
                <button className='save-btn btn ready' onClick={() => { saveConfiguration() }}>
                    {saving ? <span className='fade-out-text'>Configuration Saved!</span> : <span className='fade-in-text'>Save Configuration</span>}
                </button>
            </div>
        </div>
    </div>
}