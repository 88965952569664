import React, { useEffect, useState } from 'react';
import { Navigate, useSearchParams } from 'react-router-dom';
import { api } from './api';
import { permissions, permissionToUrl } from './permissions';
import './style-signup.css';

export function RegisterPage(): JSX.Element | null {

    const [query] = useSearchParams();
    const token = query.get('a');
    const subdomain = query.get('s');

    const [registered, setRegistered] = useState<boolean>(false);
    const [environment, setEnvironment] = useState<string>();

    useEffect(() => {
        fetch("/api/env").then(response => response.json().then(env => setEnvironment(env.environment)))
    }, [])

    useEffect(() => {
        if (subdomain && token && !registered) {
            api.register(subdomain, token).then(_ => setRegistered(true));
        }
    }, [token, subdomain, registered])

    function getIntegrationId(): number {
        switch (environment) {
            case "development": return 7;
            case "production": return 9;
            case "test": return 9;
            default: return 0;
        }
    }

    if (subdomain) {
        const adminPanel = environment === 'development' ? `https://${subdomain}.dev-admin.parla.tech` : environment === 'test' ? `https://${subdomain}.test-admin.parla.tech` : `https://${subdomain}.admin.parla.tech`;

        if (token)
            if (registered) {
                window.parent.postMessage({ action: "save" }, adminPanel);
                return <Navigate to={`/config?t=${token}&s=${subdomain}`} />
            } else
                return <div>Registering...</div>
        else if (environment) {
            window.location.href = `${adminPanel}/auth?integration=${getIntegrationId()}&permissions=${permissionToUrl(permissions)}`;
            return <link href={`${adminPanel}/auth?integration=${getIntegrationId()}&permissions=${permissionToUrl(permissions)}`}>Authorise</link>;
        } else {
            return <div>Loading...</div>
        }
    }
    return <div>Bad Request</div>
}