import { useState } from "react";
import { QuestionLabel } from "./QuestionLabel";
import { QuestionProps } from "./QuestionProps";

export function TextQuestion(props: QuestionProps) {
    const [value, setValue] = useState<string>('');
    function updateValue(e: React.ChangeEvent<HTMLInputElement>) {
        setValue(e.target.value);
        props.valueChanged(e.target.value);
    }

    return <div className="question">
        <QuestionLabel {...props}/>
        <input className="question-value-text" value={value} onChange={updateValue} />
    </div>;
}
