import { Route, Routes, useSearchParams } from 'react-router-dom';
import { PageSelection } from './PageSelection';
import { ShopConfigPage } from './ShopConfigPage';
import { EditQuestions } from './EditQuestions';
import { AssignQuestions } from './AssignQuestions';
import './config.css'
import { useEffect, useState } from 'react';
import { SubdomainResponse } from '../types/types';
import { ServiceConfigPage } from './ServiceConfigPage';
export interface ConfigPageProps {
    token: string,
    environment: string,
    subdomain: string,
    id: number,
    tenantName: string,
}

function Config() {
    const [environment, setEnvironment] = useState<string>("");
    const [query] = useSearchParams();
    const [token, setToken] = useState<string>("");
    const [id, setId] = useState<number>(0);
    const [tenantName, setTenantName] = useState<string>("");
    const [subdomain, setSubdomain] = useState<string>("");

    const urlToken = query.get('t');
    if (urlToken && token !== urlToken) {
        setToken(urlToken);
    }

    console.log("Index Reloaded", token)
    useEffect(() => {
        fetch("/api/env").then(response => response.json().then(env => setEnvironment(env.environment)))
    }, [])

    useEffect(() => {
        fetch(`/api/subdomain?t=${token}`)
            .then(response => {
                if (response.ok) {
                    response.json()
                        .then((obj: SubdomainResponse) => {
                            setSubdomain(obj.subdomain);
                            setTenantName(obj.tenantName);
                            setId(obj.id);
                        })
                }
            });
    }, [token]);

    const props = {
        token: token,
        environment: environment,
        subdomain: subdomain,
        id: id, 
        tenantName: tenantName
    }

    return (
        <Routes>
            <Route path="questions" element={<EditQuestions {...props} />} />
            <Route path="services" element={<ServiceConfigPage {...props} />} />
            <Route path="shops" element={<ShopConfigPage {...props} />} />
            <Route path="assign" element={<AssignQuestions {...props} />} />
            <Route path="/*" element={<PageSelection {...props} />} />
        </Routes>
    );
}

export default Config;