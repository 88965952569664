import { IValidateProps } from '@mobiscroll/react/dist/src/core/util/datetime';
import { getDayOfTheWeek, moveDayOfTheWeek, weekDayToDoTW } from '.';
import { DateTime } from 'luxon';
import { MbscRecurrenceRule } from '@mobiscroll/react';
import { Config } from '../types/types';

export interface OpenAndClose {
    open?: string | null,
    close?: string | null,
}

export interface DateTimeBounds {
    minDate: string,
    minTime: string | undefined,
    maxTime: string | undefined,
}

export function calculateBounds(openingHours: IValidateProps[], date: string, configuration: Config | undefined): DateTimeBounds {
    const buffer = configuration && configuration.bufferMinutes ? configuration.bufferMinutes : 0;

    console.log("Calculating Today Bounds", DateTime.now().toISO(), configuration);
    const todayOpenAndClose = getOpenAndClose(openingHours, DateTime.now().startOf('day'));

    console.log("Calculating Chosen Date", date, configuration);
    const openAndClose = getOpenAndClose(openingHours, DateTime.fromISO(date));

    const nowAndBuffer = DateTime.now().plus({ minutes: buffer });

    const result: DateTimeBounds = {
        minDate: nowAndBuffer.toISO(),
        maxTime: openAndClose.close || undefined,
        minTime: openAndClose.open || undefined
    };

    if (todayOpenAndClose.close && DateTime.fromISO(todayOpenAndClose.close) < nowAndBuffer) {
        console.log("Close is in the past, moving to tomorrow")
        result.minDate = DateTime.now().plus({ 'days': 1 }).startOf('day').toISO();
    }
    const today = DateTime.now().toISODate();
    const now = nowAndBuffer.toISOTime()!;
    if (date === today && (!openAndClose.open || openAndClose.open < now)) {
        console.log("Open is in the past, moving to now")
        result.minTime = now;
    }

    console.log("Bounds", result);
    return result;
}

type HourCheck = {
    late: DateTime | undefined;
    early: DateTime | undefined;
    lateNextDay: boolean;
    earlyPreviousDay: boolean;
}

export function getOpenAndClose(hours: IValidateProps[], time: DateTime): OpenAndClose {
    const dotw = getDayOfTheWeek(weekDayToDoTW(time.weekday));
    const date = time.startOf('day');
    console.log(time.toJSDate(), time.toISO(), date.toISO(), dotw);
    const isoDate = date.toISODate();
    const activeCheck: HourCheck = {
        early: undefined,
        late: undefined,
        lateNextDay: false,
        earlyPreviousDay: false
    }

    for (const h of hours) {
        if (h.recurring) {
            //Regular opening hours or open exception
            const recurrance = (h.recurring as MbscRecurrenceRule);
            const tempStart = DateTime.fromISO(h.start as string, { setZone: true })
            const tempEnd = DateTime.fromISO(h.end as string, { setZone: true })
            // For rollover handling we need to check the previous/next dotw as their opening hours can roll into today's
            //So if we face a negative time offset, check tomorrow to see if the start time is pulled across midnight            
            const maybeRollBackwards = (tempStart.offset - DateTime.now().offset > 0) && recurrance.weekDays === moveDayOfTheWeek(dotw, 1);
            //if we face a positive time offset, check yesterday in case the close time pulled forward
            const maybeRollForwards = (tempStart.offset - DateTime.now().offset < 0) && recurrance.weekDays === moveDayOfTheWeek(dotw, -1);
            if (recurrance.weekDays === dotw || maybeRollBackwards || maybeRollForwards) {
                if (!h.recurringException || !(h.recurringException as Array<string>).some(s => s === isoDate)) {

                    //in the case of rolling forward/backwards we need to update the ordinal +/- 1 day
                    const shift = maybeRollBackwards ? 1 : maybeRollForwards ? -1 : 0;
                    const start = tempStart.set({ year: time.year, ordinal: time.ordinal + shift }).toLocal();
                    var end = tempEnd.set({ year: time.year, ordinal: time.ordinal + shift }).toLocal();
                    if (end < start) {
                        end = end.plus({ days: 1 });
                    }
                    const currentTime = time;
                    console.log("Checking bounds", {
                        weekDay: moveDayOfTheWeek(dotw, shift),
                        TimeToCheck: currentTime.toISO(),
                        Start: start.toISO(),
                        End: end.toISO(),
                        CurrentEarly: activeCheck.early?.toISO(),
                        CurrentLate: activeCheck.late?.toISO(),
                        Shift: shift,
                        RollingBackwards: maybeRollBackwards,
                        RollingForwards: maybeRollForwards
                    })
                    if (recurrance.from && recurrance.until) {
                        const from = fromISODate(recurrance.from as string);
                        const until = fromISODate(recurrance.until as string);
                        if (from <= currentTime && until >= currentTime) {
                            checkHours(activeCheck, start, currentTime, end);
                        } else {
                            console.log("current time outside recurrance range", currentTime.toISO(), from.toISO(), until.toISO())
                        }
                    } else {
                        checkHours(activeCheck, start, currentTime, end);
                    }
                }
            }
        } else {
            //Partial closed/appointments - may be multiple records for the same day
            if ((h.start as DateTime).toLocal().startOf('day') <= date && (h.end as any).toLocal().endOf('day') >= date) {
                if (!activeCheck.early || (h.start as DateTime).toLocal() < activeCheck.early) {
                    console.log("Found Earlier Start in closed exception hours", (h.start as DateTime).toISO(), activeCheck.early?.toISO())
                    activeCheck.early = (h.start as DateTime);
                }
                if (!activeCheck.late || (h.end as DateTime).toLocal() > activeCheck.late) {
                    console.log("Found Later Finish in closed exception hours", (h.end as DateTime).toISO(), activeCheck.late?.toISO())
                    activeCheck.late = (h.end as DateTime);
                }
            }
        }
    }
    if (activeCheck.lateNextDay) {
        activeCheck.late = undefined;
    }
    if (activeCheck.earlyPreviousDay) {
        activeCheck.early = undefined;
    }
    console.log("Completed bounds check", activeCheck.early?.toISOTime(), activeCheck.late?.toISOTime())
    return { open: activeCheck.early?.toISOTime(), close: activeCheck.late?.toISOTime() };
}

function checkHours(activeCheck: HourCheck, start: DateTime, currentTime: DateTime, end: DateTime) {
    if (!activeCheck.early || start < activeCheck.early) {
        console.log("Found Earlier Start in regular hours", start.toISO(), activeCheck.early?.toISO());
        if (currentTime.ordinal > start.ordinal || currentTime.year > start.year) {
            console.log("Start is the previous day, clearing start", end.toISO(), activeCheck.late?.toISO());
            activeCheck.earlyPreviousDay = true;
        } else {
            activeCheck.early = start;
        }
    }
    if (!activeCheck.late || end > activeCheck.late) {
        console.log("Found Later Finish in regular hours", end.toISO(), activeCheck.late?.toISO());
        //day won't work over the month boundary, ordinal won't work over the year
        if (currentTime.ordinal < end.ordinal || currentTime.year < end.year) {
            console.log("Finish is the next day, clearing end", end.toISO(), activeCheck.late?.toISO());
            activeCheck.lateNextDay = true;
        } else {
            activeCheck.late = end;
            if (end < DateTime.now() && activeCheck.earlyPreviousDay) {
                console.log("Closed before now with early flag, clearing flag");
                //We cleared the start because it rolled midnight, but the hour closed before current time
                //so dismiss the flag
                activeCheck.early = undefined;
                activeCheck.earlyPreviousDay = false;
            }
        }
    }
}

function fromISODate(date: string): DateTime {
    return DateTime.fromISO(DateTime.fromISO(date).toISODate()!);
}