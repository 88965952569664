import { Question } from '../types/types';

export function ManagedQuestion(props: { question: Question; remove: () => void; edit: () => void; }): JSX.Element {
    return <li className='question-item'>
        {props.question.question}
        <div className='edit-question-buttons'>
            <div className='btn btn-question-create' onClick={props.edit}>
                <i className='fas fa-edit'></i>
            </div>
            <div className='btn danger-btn' onClick={props.remove}>
                <i className='fas fa-trash'></i>
            </div>
        </div>
    </li>;
}
