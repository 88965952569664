import React, { useState } from 'react';
import { Department, Shop } from './types/types';
import { Thing } from './Thing';
import { ServiceNode } from './ServiceNode';

export function DepartmentNode(props: { selected: Thing; department: Department; parent: Shop; onChange: (thing: Thing) => void; }): JSX.Element {
    const [expanded, setExpanded] = useState<boolean>(false);

    function expand(e: React.MouseEvent<HTMLDivElement, MouseEvent>) {
        e.stopPropagation();
        setExpanded(e => !e);
    }

    function selectDepartment(e: React.MouseEvent<HTMLDivElement, MouseEvent>) {
        e.stopPropagation();
        if (!expanded)
            setExpanded(true);        
        props.onChange({
            company: props.selected.company,
            shopId: props.parent.id,
            shop: props.parent.name,
            departmentId: props.department.id,
            department: props.department.name,
            serviceId: undefined,
            service: undefined
        });
    }

    return <div className='department-row'>
        <div onClick={(e) => { selectDepartment(e); }}>
            <span onClick={expand}><i className={expanded ? 'fas fa-chevron-down tree-icon' : 'fas fa-chevron-right tree-icon'}></i></span>
            <span className={props.selected?.departmentId === props.department.id ? 'selected department' : 'department'}>{props.department.name}</span>
        </div>
        {expanded ? <div>{props.department.services?.map(s => <ServiceNode selected={props.selected} key={s.appointmentTemplate.id} service={s} parent={props.department} shop={props.parent} onChange={props.onChange} />)}</div> : null}
    </div>;

}
