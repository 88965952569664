import React, { useEffect, useState } from 'react';
import { Department, Shop } from './types/types';
import { Thing } from './Thing';
import { DepartmentNode } from './DepartmentNode';

export function ShopNode(props: { selected: Thing; subdomain: string | undefined; shop: Shop; onChange: (thing: Thing) => void; }): JSX.Element {

    const [expanded, setExpanded] = useState<boolean>(false);
    const [departments, setDepartments] = useState<Department[]>();

    function expand(e: React.MouseEvent<HTMLDivElement, MouseEvent>) {
        e.stopPropagation();
        setExpanded(e => !e);
    }
    function selectShop(e: React.MouseEvent<HTMLDivElement, MouseEvent>) {
        e.stopPropagation();
        if (!expanded)
            setExpanded(true);
        props.onChange({
            company: props.selected.company,
            shopId: props.shop.id,
            shop: props.shop.name,
            departmentId: undefined,
            department: undefined,
            serviceId: undefined,
            service: undefined
        });
    }

    useEffect(() => {
        if (expanded && props.subdomain) {
            fetch(`/api/shop/${props.subdomain}/${props.shop.id}`).then(r => r.json()).then((shop: Shop) => setDepartments(shop.departments)
            );
        }
    }, [expanded, props.shop, props.subdomain]);



    return <div className='shop-row'>
        <div onClick={(e) => { selectShop(e); }}>
            <span onClick={expand}><i className={expanded ? 'fas fa-chevron-down tree-icon' : 'fas fa-chevron-right tree-icon'}></i></span>
            <span className={props.selected?.shopId === props.shop.id ? 'selected shop' : 'shop'}>{props.shop.name}</span>
        </div>
        {expanded ? <div>{departments?.map(d => <DepartmentNode selected={props.selected} key={d.id} department={d} parent={props.shop} onChange={props.onChange} />)}</div> : null}
    </div>;
}
