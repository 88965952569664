import React from 'react';
import { Department, Service, Shop } from './types/types';
import { Thing } from './Thing';

export function ServiceNode(props: { selected: Thing; service: Service; parent: Department; shop: Shop; onChange: (thing: Thing) => void; }): JSX.Element {

    function selectService(e: React.MouseEvent<HTMLDivElement, MouseEvent>) {
        e.stopPropagation();
        props.onChange({
            company: props.selected.company,
            shopId: props.shop.id,
            shop: props.shop.name,
            departmentId: props.parent.id,
            department: props.parent.name,
            serviceId: props.service.appointmentTemplate.id,
            service: props.service.appointmentTemplate.name
        });
    }

    return <div className='service-row' onClick={(e) => { selectService(e); }}>
        <i className='fas fa-circle tree-icon'/>
        {/*props.selected?.departmentId === props.parent.id &&*/props.selected?.serviceId === props.service.appointmentTemplate.id ?
            <div className='selected service'>
                <span>{props.service.appointmentTemplate.name}</span>
            </div>
            :
            <div className='service'>
                <span>{props.service.appointmentTemplate.name}</span>
            </div>}
    </div>;

}
