export const api = {
    register,
}

function register(subdomain:string, token:string):Promise<Response> {
    const options = {
        method: "POST",
        headers: {
            "Content-Type": "application/json",
        },
        body: JSON.stringify({
            subdomain: subdomain,
            token:token,
        })
    }
    return fetch("/api/register", options);
}