import { useEffect, useState } from "react";
import { Question } from "../types/types";

interface CreateQuestionProps {
    tenantId: number;
    question?: Question;
    questionCreated: () => void;
}

export function CreateQuestion(props: CreateQuestionProps): JSX.Element {
    const [questionId, setQuestionId] = useState<number>();
    const [question, setQuestion] = useState<string>('');
    const [answerType, setAnswerType] = useState<string>('text');
    const [choices, setChoices] = useState<string[]>([]);

    useEffect(() => {
        if (props.question) {
            setQuestionId(props.question.id);
            setQuestion(props.question.question);
            setAnswerType(props.question.questionType);
            setChoices(props.question.choices);
        }
    }, [props.question])

    function cancelEdit() {
        setQuestionId(undefined);
        setQuestion('');
        setAnswerType('text');
        setChoices([]);
    }

    async function createQuestion() {
        const options = {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
            },
            body: JSON.stringify({ question: question, questionType: answerType, choices: choices, tenantId: props.tenantId })
        }
        if (questionId) {
            options.method = "PUT";
            options.body = JSON.stringify({ id: questionId, question: question, questionType: answerType, choices: choices, tenantId: props.tenantId })
        }
        await fetch(`/api/questions`, options);
        props.questionCreated();
    }

    function updateChoices(value: string) {
        setChoices(value.split('\n'));
    }


    return <div>
        <div>
            <h4>Question:</h4>
            <input className="question-creator-panel-input" maxLength={255} value={question} onChange={(e) => { setQuestion(e.target.value) }} />
        </div>
        <br />
        <div>
            <h4>Answer Type:</h4>
            <select className="question-creator-panel-select" value={answerType} onChange={(e) => { console.log(e); setAnswerType(e.target.value) }}>
                <option value={'text'}>Open Text</option>
                <option value={'single'}>Multiple Choice (Select One)</option>
                <option value={'multi'}>Multiple Choice (Select Many)</option>
            </select>
            {answerType !== 'text' ? <textarea placeholder="Add each option as a new row" className="question-creator-panel-textarea" value={choices.join('\n')} onChange={(e) => updateChoices(e.target.value)} /> : null}
        </div>
        <div className="manage-question-button">
            <button className={questionId ? "btn-question-create btn" : "save-btn btn ready"} onClick={() => createQuestion()}>{questionId ? 'Update' : 'Create'} Question</button>
            {questionId ? <button className={questionId ? "danger-btn btn" : "save-btn btn ready"} onClick={() => cancelEdit()}>Cancel</button> : null}
        </div>
    </div>

}