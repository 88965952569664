import { useState } from "react";
import { QuestionLabel } from "./QuestionLabel";
import { QuestionProps } from "./QuestionProps";

export function CheckboxQuestion(props: QuestionProps) {
    const [value, setValue] = useState<string[]>([]);
    function updateValue(e: React.ChangeEvent<HTMLInputElement>) {
        const existingValues = value;
        if (e.target.checked) {
            existingValues.push(e.target.value);
        } else {
            var index = existingValues.findIndex(x => x === e.target.value);
            if (index > -1)
                existingValues.splice(index, 1);
        }
        setValue([...existingValues]);
        props.valueChanged(existingValues.join(','));
    }

    return <div className="question">
        <QuestionLabel {...props} />
        <div className="question-value-check">
            {props.question.choices.map(c => <label key={c}><input type='checkbox' name={props.question.id.toString()} value={c} checked={value.some(x => x === c)} onChange={updateValue} />{c}</label>
            )}
        </div>
    </div>;
}
