export interface RegisterRequest {
    subdomain: string,
    token: string
}

export interface CustomerRequest {
    zip: string
    address: string,
    subdomain: string,
    name: string,
    phone: string | null,
    email: string | null,
    marketing: boolean,
    notes: string[],
    service: ServiceInfo,
    appointmentTime: string,
    locale: string,
    timeZone: string
}

export interface ServiceInfo {
    departmentId: number,
    serviceId: number,
    duration: string
}

export interface ConfigRequest {
    tenant_id: number,
    service_id?: number,
    shop_id?: number,
    config: Config
}

export interface Config {
    isDefault?: boolean,
    bufferMinutes?: number,
    slotSize?: number,
    mandatoryFields?: string[]
}

export interface Customer {
    id: number,
}

export interface Address {
    name: string,
    line1: string,
    line2: string,
    line3: string,
    line4: string,
    area: string,
    region: string,
    postcode: string,
    country: string,
}

export interface QuestionLink{
    id: number,
    question_id: number,
    generic: boolean,
    shop_id?: number,
    department_id?: number,
    service_id?: number,
    sequence: number,
    mandatory: boolean,
}

export interface Question {    
    id: number,
    tenantId: number,
    question: string,
    questionType: string,
    choices: string[]
}

export interface OrderedQuestion {
    id: number,
    linkId: number,
    tenantId: number,
    question: string,
    questionType: string,
    choices: string,
    sequence: number,
    mandatory: boolean
}

export interface PagingList<T> {
    items: T[],
    count: number
}

export interface Shop {
    id: number,
    name: string,
    address: Address,
    departments: Department[],
}

export interface Department {
    id: number,
    name: string,
    services: Service[],
    staffCount: number,
    shopId: number,
    shopName: string,
}

export interface Service {
    appointmentTemplate: AppointmentTemplate
}

export interface AppointmentTemplate {
    id: any;
    name: string,
    duration: string,
    virtual: boolean
}

export type LinkKind = 'generic' | 'shop' | 'department' | 'service';

export interface Appointment {
    title: string,
    start: string,
    end: string,
    bufferBefore: string,
    bufferAfter: string,    
    customers: {customerId: number}[],
    appointmentTemplateId: number,
    departmentId: number,
    notes: {content: string, type: number}[],
    videoCall?: { roomName: string, journeyId: number, roomType: number }
}

export interface SubdomainResponse {
    subdomain: string,
    id: number,
    tenantName: string
}

export enum ExceptionStatus {
    open,
    close
}

export interface RecurringHours {
    dayOfTheWeek: number,
    start: string,
    end: string,
    timeZone: string,
}

export interface OpeningHoursException extends RecurringHours {
    status: ExceptionStatus;
    startDate: string,
    endDate: string,
}

export interface OpeningHours extends RecurringHours {
    closed: boolean
}

export interface Asset {
    content: string,
    contentType: string,
}

export interface TenantHome {
    background: Asset,
    small: Asset,
    text: Asset,
}

export interface Hours {
    openingHours: OpeningHours[]
    exceptions: OpeningHoursException[]
}

export interface OpenHours {
    shop: Hours,
    department: Hours
}

export interface BusyTime {
    start: string,
    end: string,
    count: number
}
