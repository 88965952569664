import { useState } from "react";
import { QuestionLabel } from "./QuestionLabel";
import { QuestionProps } from "./QuestionProps";

export function SelectQuestion(props: QuestionProps) {
    const [value, setValue] = useState<string>(props.question.choices.length > 0 ? props.question.choices[0] : '');

    function updateValue(e: React.ChangeEvent<HTMLSelectElement>) {
        setValue(e.target.value);
        props.valueChanged(e.target.value);
    }

    return <div className="question">
        <QuestionLabel {...props} />
        <select className="question-value-select" value={value} onChange={updateValue}>
            {props.question.choices.map(c => <option key={c} value={c}>{c}</option>)}
        </select>
    </div>;
}
