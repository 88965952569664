import { useState } from "react";
import { QuestionLabel } from "./QuestionLabel";
import { QuestionProps } from "./QuestionProps";

export function RadioQuestion(props: QuestionProps) {
    const [value, setValue] = useState<string>('');
    function updateValue(e: React.ChangeEvent<HTMLInputElement>) {
        setValue(e.target.value);
        props.valueChanged(e.target.value);
    }

    return <div className="question">
        <QuestionLabel {...props}/>
        <div className="question-value-radio">
            {props.question.choices.map(c => <label key={c}><input type='radio' name={props.question.id.toString()} checked={value === c} value={c} onChange={updateValue} />{c}</label>
            )}
        </div>
    </div>;
}
