export function nameof<T>(name: keyof T) {
    return name;
}

export function weekDayToDoTW(weekDay: number): number {
    if (weekDay === 7)
        return 0
    else return weekDay;
}

export function getDayOfTheWeek(dayOfTheWeek: number): string {
    switch (dayOfTheWeek) {
        case 0: return "SU";
        case 1: return "MO";
        case 2: return "TU";
        case 3: return "WE";
        case 4: return "TH";
        case 5: return "FR";
        case 6: return "SA";
    }
    return "";
}

export function moveDayOfTheWeek(dayOfTheWeek: string, count: number): string {
    let day = 0;
    switch (dayOfTheWeek) {
        case "SU": day = 0; break;
        case "MO": day = 1; break;
        case "TU": day = 2; break;
        case "WE": day = 3; break;
        case "TH": day = 4; break;
        case "FR": day = 5; break;
        case "SA": day = 6; break;
    }
    day += count;
    day = (day % 7);
    return getDayOfTheWeek(day);
}