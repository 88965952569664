import React, { useState } from 'react';
import { Shop } from './types/types';
import { Thing } from './Thing';
import { ShopNode } from './ShopNode';

export function ShopTree(props: { shops: Shop[]; subdomain: string | undefined, tenantName: string; onChange: (thing: Thing) => void; }) {
    const [selected, setSelected] = useState<Thing>({ company: 'All Shops' });

    function selectCompany(e: React.MouseEvent<HTMLDivElement, MouseEvent>) {
        const thing = {
            company: 'All Shops',
            shopId: undefined,
            shop: undefined,
            departmentId: undefined,
            department: undefined,
            serviceId: undefined,
            service: undefined
        };
        e.stopPropagation();
        setSelected(thing);
        props.onChange(thing);
    }
    return <div className='question-links'>
        <div className='company-row'>
            <div onClick={(e) => { selectCompany(e); }}>
                <i className='fas fa-chevron-down tree-icon' />
                <span className='selected'>All Shops</span>
            </div>
            {props.shops?.map(s => <ShopNode selected={selected} subdomain={props.subdomain} key={s.id} shop={s} onChange={(s) => { setSelected(s); props.onChange(s); }} />)}
        </div>
    </div>;
}
