import { Component, useState } from "react";
import './error.css';

function ErrorResponse(props) {
    const [showData, setShowData] = useState(false);
    const [sendingData, setDataSending] = useState(false);
    const [dataSent, setDataSent] = useState(false);
    const { error } = props;
    const [date] = useState(new Date());

    function sendLogs() {
        setDataSending(true);

        const options = {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'X-Scope-OrgID': 'Parla'
            },
            body: JSON.stringify({
                streams: [
                    {
                        stream: { system: "Customer Scheduler", device: navigator.userAgent, },
                        values: [
                            [`${date.valueOf() * 1000000}`, `${window.location.pathname} ${error.message}`],
                        ]
                    }
                ]
            })
        }

        fetch('https://logging.apps.parla.tech/loki/api/v1/push', options).then(_ => {
            setDataSending(false);
            setDataSent(true);
        }).catch(setDataSending(false))
    }

    function buttonContent() {
        if (sendingData)
            return <i className="fa-spin fa fa-spinner"></i>
        if (dataSent)
            return <span>We will let you know when it's fixed {<i className="fas fa-check"></i>}</span>;
        return 'Send to Parla';
    }

    return <div className='error'>
        <h1>Sorry... there was an error</h1>
        <button className='btn btn-secondary' onClick={() => { setShowData(true); }}>View the information</button>
        {showData ?
            <div className='data'>
                <div className='item'>
                    <label>Date and Time:</label>
                    <span>{date.toISOString()}</span>
                </div>
                <div className='item'>
                    <label>Device:</label>
                    <span>{navigator.userAgent}</span>
                </div>
                <div className='item'>
                    <label>Path:</label>
                    <span className='message'>{window.location.pathname}</span>
                </div>
                <div className='item'>
                    <label>Message:</label>
                    <span className='message'>{error.message}</span>
                </div>
                <div className='item stack' >
                    <label>Stack:</label>
                    <span>{error.stack}</span>
                </div>
            </div>
            : null}
        <button className='btn btn-primary' enabled={!sendingData && !dataSent} onClick={() => sendLogs()}>{buttonContent()}</button>
    </div>;
}

class ErrorWrapper extends Component {
    state = {
        hasError: this.props.hasError,
        error: this.props.error
    };

    static getDerivedStateFromError(error) {
        console.error("getDerivedStateFromError:", error);
        // Update state so the next render will show the fallback UI.
        return { hasError: true, error };
    }

    componentDidCatch(error, errorInfo) {
        console.error("Uncaught error:", error, errorInfo);
    }

    render() {
        if (this.state.hasError) {
            return <ErrorResponse error={this.state.error} />
        }
        try {
            return this.props.children;
        }
        catch (error) {
            this.setState({ hasError: true, error });
        }
    }
}

export default ErrorWrapper;
