import { useEffect, useState } from "react";
import { Question } from '../types/types';
import { ConfigPageProps } from ".";
import { useNavigate } from "react-router-dom";
import { CreateQuestion } from './CreateQuestion';
import { ManagedQuestion } from "./ManagedQuestion";

export function EditQuestions(props: ConfigPageProps) {
    const [activeQ, setActiveQ] = useState<Question>();
    const [editingQuestion, setEditingQuestion] = useState<Question>();
    const [questions, setQuestions] = useState<Question[]>([]);
    const { token, id } = props;
    const navigate = useNavigate();

    useEffect(() => {
        loadQuestions();
    },[]);

    function loadQuestions() {
        //Load generic questions 
        fetch(`/api/questions?t=${token}`).then(r => r.json()).then((questions) => {
            const qs: Question[] = [];
            for (const q of questions) {
                qs.push({ ...q, choices: JSON.parse(q.choices) });
            }
            setQuestions(qs)
        });
    }
    function questionCreated() {
        loadQuestions();
    }

    async function startRemove(q: Question) {
        const response = await fetch(`/api/questions/${q.id}`)
        const question = await response.json();
        if (question.links > 0) {
            setActiveQ(q);
        } else {
            deleteQuestion(q);
        }
    }

    function startEdit(q: Question) {
        setEditingQuestion(q);
    }

    async function deleteQuestion(question: Question) {
        setQuestions(q => q.filter(qu => qu.id !== question.id))
        await fetch(`/api/questions/${question.id}`, { method: 'DELETE' })
    }

    return <div className="question-creator-panel">
        <div className='question-creator-panel-title'>
            <h3>Manage Questions</h3>
            <div onClick={() => navigate("config")}><i className="fas fa-chevron-left"></i> Back</div>
        </div>
        <span className='question-creator-panel-info'>Manage all your questions in this panel and then use the Question Manager to assign them to different shops, departements or services.</span>
        <h4 className='manage-question-title'>Create a new question</h4>
        <div className='manage-question-field'>
            <div className='question-creator-panel-form' onClick={(e) => e.stopPropagation()}><CreateQuestion question={editingQuestion} tenantId={id!} questionCreated={questionCreated} /></div>
        </div>
        <h4 className='manage-question-title'>Existing questions</h4>
        <div className='manage-question-field'>
            {questions.map(q => <ManagedQuestion key={q.id} question={q} remove={() => startRemove(q)} edit={() => startEdit(q)}/>)}
        </div>

        {activeQ ? <div className='modal-wrap top'>
            <div className='modal'>
                The Question "{activeQ.question}?" is in use, are you sure you want to delete it?
                <div className='modal-buttons'>
                    <button className='btn danger-btn' onClick={() => { deleteQuestion(activeQ); setActiveQ(undefined) }}>Confirm Delete</button>
                    <button className='btn btn-question-create' onClick={() => setActiveQ(undefined)}>Cancel</button>
                </div>
            </div>
        </div> : null}
    </div>
}